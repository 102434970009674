import { collection, doc, addDoc, getDoc, setDoc } from "firebase/firestore";

import { db } from "./firebaseConfig";

export const createOrganization = async (organizationData) => {
  const organizationRef = collection(db, "organizations");
  try {
    const organizationDocRef = await addDoc(organizationRef, organizationData);
    console.log(organizationDocRef.id);
    return organizationDocRef.id;
  } catch (error) {
    // Error is caught but not rethrown or logged
    return null; // or return a default value or ID as appropriate
  }
};

export const getOrganizationProfile = async (organizationId) => {
  const docRef = doc(db, "organizations", organizationId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Error("No such organization profile!");
  }
};

export const updateOrganizationProfile = async (
  organizationId,
  profileData,
) => {
  const docRef = doc(db, "organizations", organizationId);
  await setDoc(docRef, profileData, { merge: true });
};
