import { doc, getDoc, setDoc } from "firebase/firestore";

import { db } from "./firebaseConfig";
import { fetchInChunks } from "../utils/firestoreUtils";

export const getUserById = async (userId) => {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Error("No such user profile!");
  }
};

export const updateUserProfile = async (userId, profileData) => {
  const docRef = doc(db, "users", userId);
  await setDoc(docRef, profileData, { merge: true });
};

export const getUsersByIds = async (userIds) => {
  return await fetchInChunks("users", userIds);
};
