import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Divider } from "antd";
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";

import TableControls from "./TableControls";
import AssetsTable from "./AssetsTable";

const { Content } = Layout;

const AssetsPage = () => {
  const [currentTableView, setCurrentTableView] = useState("all");
  const navigate = useNavigate();

  const handleTableViewSelect = (tableView) => {
    if (currentTableView !== tableView) {
      setCurrentTableView(tableView);
    }
  };

  const tableViews = {
    all: {
      text: "All Assets",
      action: () => handleTableViewSelect("all"),
      icon: <AppstoreOutlined />,
    },
    rank: {
      text: "Assets By Category",
      action: () => handleTableViewSelect("category"),
      icon: <CheckCircleOutlined />,
    },
    department: {
      text: "Assets By Status",
      action: () => handleTableViewSelect("status"),
      icon: <UserOutlined />,
    },
  };

  const handleAddAsset = () => {
    navigate("/assets/new");
  };

  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <TableControls
        addButtonText={"Add Asset"}
        currentTableView={currentTableView}
        handleAddButton={handleAddAsset}
        tableViews={tableViews}
      />
      <Divider style={{ margin: 0 }} />
      <AssetsTable currentTableView={currentTableView} key={currentTableView} />
    </Content>
  );
};

export default AssetsPage;
