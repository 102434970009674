import React from "react";
import { Form, Input, Button, message, Card } from "antd";
import { MailOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, values.email)
      .then(() => {
        message.success(
          "If there is an account associated with this email, a reset link has been sent.",
        );
        navigate("/login");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          width: "300px",
          padding: "40px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "10px",
          position: "relative",
          backgroundColor: "inherit",
        }}
      >
        <Link
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            fontSize: "16px",
          }}
          to="/login"
        >
          <ArrowLeftOutlined />
        </Link>
        <Form form={form} name="password_reset" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email address!" },
            ]}
          >
            <Input placeholder="Email" prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Reset Password
            </Button>
          </Form.Item>
          <div style={{ marginTop: "16px" }}>
            <Link style={{ float: "right" }} to="/signup">
              Sign Up
            </Link>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default PasswordReset;
