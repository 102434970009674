import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Card, Table, Col, Row, message, Divider } from "antd";
import { RightOutlined } from "@ant-design/icons";

// import PreviousReports from "../services/PreviousReports";
import { UserContext } from "../contexts/UserContext";
import { getOfficersAdminOnly } from "../services/officerService";
import {
  getCheckedOutAssetsByOrg,
  getInventoryBreakdownByOrg,
} from "../services/assetService";
import { getMaintenanceHistoryByOrg } from "../services/maintenanceService";
// import useFetchData from "../hooks/useFetchData";

const { Content } = Layout;

const checkoutsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Due",
    dataIndex: "returnDate",
    key: "returnDate",
    align: "center",
  },
];

const inventoryColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Available",
    dataIndex: "available",
    key: "available",
    align: "center",
  },
  {
    title: "Assigned",
    dataIndex: "assigned",
    key: "assigned",
    align: "center",
  },
  {
    title: "Checked Out",
    dataIndex: "checkedOut",
    key: "checkedOut",
    align: "center",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "center",
  },
];

// const maintenanceHistoryColumns = [
//   {
//     title: "Asset",
//     dataIndex: "assetName",
//     key: "assetName",
//     align: "center",
//   },
//   {
//     title: "Status",
//     dataIndex: "status",
//     key: "status",
//     align: "center",
//   },
//   // {
//   //   title: "Scheduled Date",
//   //   dataIndex: "scheduledDate",
//   //   key: "scheduledDate",
//   // },
//   // {
//   //   title: "Completed Date",
//   //   dataIndex: "completedDate",
//   //   key: "completedDate",
//   // },
//   {
//     title: "Maintenance Required",
//     dataIndex: "maintenanceRequired",
//     key: "maintenanceRequired",
//     align: "center",
//   },
//   {
//     title: "Scheduled",
//     dataIndex: "scheduled",
//     key: "scheduled",
//     align: "center",
//   },
//   {
//     title: "Performed",
//     dataIndex: "performed",
//     key: "performed",
//     align: "center",
//   },
//   {
//     title: "Back in Service",
//     dataIndex: "backInService",
//     key: "backInService",
//     align: "center",
//   },
// ];

const officersColumns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    align: "center",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    align: "center",
  },
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
    align: "center",
  },
  {
    title: "Unit",
    dataIndex: "unit",
    key: "unit",
    align: "center",
  },
];

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [checkouts, setCheckouts] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [maintenanceHistory, setMaintenanceHistory] = useState([]);
  const [officers, setOfficers] = useState([]);
  const [isOfficersLoading, setIsOfficersLoading] = useState(true);
  const [isInventoryLoading, setIsInventoryLoading] = useState(true);
  const [isCheckoutsLoading, setIsCheckoutsLoading] = useState(true);
  const [isMaintenanceHistoryLoading, setIsMaintenanceHisttoryLoading] =
    useState(true);

  useEffect(() => {
    const fetchCheckouts = async () => {
      if (!checkouts.length && user) {
        try {
          let checkoutData = await getCheckedOutAssetsByOrg(
            user.organizationId,
          );

          checkoutData = checkoutData.map((checkout) => ({
            ...checkout,
            returnDate: checkout.returnDate.toDate().toLocaleDateString(),
          }));

          setCheckouts(checkoutData);
        } catch (error) {
          message.error(`Error fetching checkouts: ${error.message}`);
        } finally {
          setIsCheckoutsLoading(false);
        }
      }
    };

    fetchCheckouts();
  }, [checkouts.length, user]);

  useEffect(() => {
    const fetchMaintenanceHistory = async () => {
      if (!maintenanceHistory.length && user) {
        try {
          const maintenanceHistoryData = await getMaintenanceHistoryByOrg(
            user.organizationId,
          );

          setMaintenanceHistory(maintenanceHistoryData);
        } catch (error) {
          message.error(`Error fetching maintenance history: ${error.message}`);
        } finally {
          setIsMaintenanceHisttoryLoading(false);
        }
      }
    };

    fetchMaintenanceHistory();
  }, [maintenanceHistory.length, user]);

  useEffect(() => {
    const fetchInventory = async () => {
      if (!inventory.length && user) {
        try {
          const inventoryData = await getInventoryBreakdownByOrg(
            user.organizationId,
          );

          setInventory(inventoryData);
        } catch (error) {
          message.error(`Error fetching inventory: ${error.message}`);
        } finally {
          setIsInventoryLoading(false);
        }
      }
    };
    fetchInventory();
  }, [inventory.length, user]);

  useEffect(() => {
    const fetchOfficers = async () => {
      if (!officers.length && user) {
        try {
          const officersData = await getOfficersAdminOnly(
            user.organizationId,
            user.role,
          );

          setOfficers(officersData);
        } catch (error) {
          message.error(`Error fetching officers: ${error.message}`);
        } finally {
          setIsOfficersLoading(false);
        }
      }
    };

    fetchOfficers();
  }, [officers.length, user]);

  // const formatMaintenanceHistory = (maintenanceHistory) =>
  //   maintenanceHistory.map((maintenance) => {
  //     let maintenanceRequired = null;
  //     let scheduled = null;
  //     let performed = null;
  //     let backInService = null;

  //     switch (maintenance.status) {
  //       case "Back in Service":
  //         backInService = "✅";
  //       // Fall through to the next case
  //       case "Performed":
  //         performed = "✅";
  //       // Fall through to the next case
  //       case "Scheduled":
  //         scheduled = "✅";
  //       // Fall through to the next case
  //       case "Maintenance Required":
  //         maintenanceRequired = "✅";
  //         break;
  //       default:
  //         // Handle other statuses or do nothing
  //         break;
  //     }

  //     return {
  //       ...maintenance,
  //       maintenanceRequired,
  //       scheduled,
  //       performed,
  //       backInService,
  //     };
  //   });

  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <Row gutter={[16, 16]} style={{ background: "#fff", margin: "24px" }}>
        <Col md={12} xs={24} xxl={8}>
          <Card
            extra={
              <Link to="/checkouts">
                <RightOutlined />
              </Link>
            }
            loading={isCheckoutsLoading}
            style={{ margin: "0 auto", maxWidth: "992px", minHeight: "500px" }}
            title="Items Due Soon"
          >
            <Table
              bordered
              columns={checkoutsColumns}
              dataSource={checkouts}
              pagination={{ pageSize: 5 }}
              rowKey="id"
              scroll={{ x: "max-content" }}
            />
          </Card>
        </Col>
        <Col md={12} xs={24} xxl={8}>
          <Card
            extra={
              <Link to="/assets">
                <RightOutlined />
              </Link>
            }
            loading={isInventoryLoading}
            style={{ margin: "0 auto", maxWidth: "992px", minHeight: "500px" }}
            title="Inventory Snapshot"
          >
            <Table
              bordered
              columns={inventoryColumns}
              dataSource={inventory}
              pagination={{ pageSize: 5 }}
              rowKey="id"
              scroll={{ x: "max-content" }}
            />
          </Card>
        </Col>
        <Col md={12} xs={24} xxl={8}>
          <Card
            extra={
              <Link to="/officers">
                <RightOutlined />
              </Link>
            }
            loading={isOfficersLoading}
            style={{ margin: "0 auto", maxWidth: "992px", minHeight: "500px" }}
            title="Officer Roster"
          >
            <Table
              bordered
              columns={officersColumns}
              dataSource={officers}
              pagination={{ pageSize: 5 }}
              rowKey="id"
              scroll={{ x: "max-content" }}
            />
          </Card>
        </Col>
        <Col md={12} xs={24} xxl={8}>
          <Card
            extra={
              <Link to="/reports">
                <RightOutlined />
              </Link>
            }
            loading={isMaintenanceHistoryLoading}
            style={{ margin: "0 auto", maxWidth: "992px", minHeight: "500px" }}
            title="Recently Run Reports"
          >
            {/* <PreviousReports /> */}
            {/* <Table
              bordered
              columns={maintenanceHistoryColumns}
              dataSource={formatMaintenanceHistory(maintenanceHistory)}
              pagination={{ pageSize: 5 }}
              rowKey="id"
              scroll={{ x: "max-content" }}
            /> */}
          </Card>
        </Col>
      </Row>{" "}
    </Content>
  );
};

export default Dashboard;
