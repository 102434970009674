import React, { useState } from "react";
import { Modal, Input, Form } from "antd";

const DenyCheckoutRequestsModal = ({ isVisible, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const [reason, setReason] = useState("");

  const handleOk = async () => {
    try {
      await form.validateFields();
      onSubmit(reason);
      setReason("");
      form.resetFields();
    } catch (info) {
      console.log("Validate Failed:", info);
    }
  };

  return (
    <Modal
      cancelText="Cancel"
      okText="Submit"
      open={isVisible}
      title="Deny Checkout Request"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          label="Reason for Denial"
          name="reason"
          rules={[
            { required: true, message: "Please input the reason for denial!" },
          ]}
        >
          <Input.TextArea
            rows={4}
            onChange={(e) => setReason(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DenyCheckoutRequestsModal;
