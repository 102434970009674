import React from "react";
import { Form, Input, Button, Card, notification } from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  BankOutlined,
  TeamOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";

import { db } from "../services/firebaseConfig";
import { createOrganization } from "../services/organizationService";

const SignUp = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        if (userCredential && userCredential.user) {
          const organizationId = await createOrganization({
            name: values.organizationName,
            size: values.organizationSize,
            ownerId: userCredential.user.uid,
          });

          const userDocRef = doc(db, "users", userCredential.user.uid);
          await setDoc(userDocRef, {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            nickname: values.nickname,
            role: "Organization Admin",
            organizationId, // Directly use the organization ID returned from createOrganization
          });

          notification.success({
            message: "Sign Up Successful",
            description:
              "You have successfully registered and created your organization!",
          });

          navigate("/login");
        } else {
          throw new Error("User authentication failed");
        }
      })
      .catch((error) => {
        notification.error({
          message: "Sign Up Failed",
          description: error.message,
        });
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          width: "300px",
          position: "relative",
          backgroundColor: "inherit",
        }}
      >
        <Link
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            fontSize: "16px",
          }}
          to="/login"
        >
          <ArrowLeftOutlined />
        </Link>
        <Form name="sign_up" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input placeholder="Email" prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password placeholder="Password" prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            hasFeedback
            dependencies={["password"]}
            name="confirm"
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!",
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your first name!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="First Name" prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input your last name!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Last Name" prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            name="nickname"
            rules={[
              {
                required: true,
                message: "Please input your nickname!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Nickname" prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            name="organizationName"
            rules={[
              {
                required: true,
                message: "Please input your organization's name!",
              },
            ]}
          >
            <Input
              placeholder="Organization's Name"
              prefix={<BankOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="organizationSize"
            rules={[
              {
                required: true,
                message: "Please input your organization's size!",
              },
            ]}
          >
            <Input
              placeholder="Organization's Size"
              prefix={<TeamOutlined />}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Register
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SignUp;
