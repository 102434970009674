import React, { useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Layout,
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Row,
  Col,
  Divider,
  Menu,
} from "antd";
import {
  DashboardOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  LogoutOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  MenuOutlined,
  AuditOutlined,
  // ToolOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

import NavigationMenu from "./NavigationMenu";
import { logoutUser } from "../services/authService";
import logoImagePath from "../filled_preside.png";
import { UserContext } from "../contexts/UserContext"; // Ensure this path matches your project structure

const { Header, Sider } = Layout;

const menuConfig = {
  Navigation: [
    { key: "/", label: "Dashboard", icon: <DashboardOutlined /> },
    { key: "/checkouts", label: "Checkouts", icon: <AuditOutlined /> },
    { key: "/assets", label: "Assets", icon: <FolderOpenOutlined /> },
    { key: "/officers", label: "Officers", icon: <TeamOutlined /> },
    // { key: "/maintenance", label: "Maintenance", icon: <ToolOutlined /> },
    { key: "/reports", label: "Reports", icon: <FileTextOutlined /> },
  ],
  Account: [
    { key: "/user-profile", label: "User Profile", icon: <UserOutlined /> },
    {
      key: "/organization-profile",
      label: "Organization Profile",
      icon: <SettingOutlined />,
    },
    { key: "logout", label: "Logout", icon: <LogoutOutlined /> },
  ],
};

const AppSidebar = ({ onCollapse, sideBarWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [supportMenuVisible, setSupportMenuVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { user } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      await logoutUser();
      navigate("/login");
    } catch (error) {
      console.error(`Logout failed: ${error.message}`);
    }
  };

  const handleMenuItemClick = (e) => {
    if (e.key === "logout") {
      handleLogout();
    } else {
      navigate(e.key);
    }
    setDrawerVisible(false);
  };

  const getActiveMenuItem = () => {
    const path = location.pathname.split("/")[1];
    return `/${path}` || "/";
  };

  // const renderSupportMenu = () => {
  //   const supportMenuItems = [
  //     { key: "/user-profile", label: "User Profile", icon: <UserOutlined /> },
  //     {
  //       key: "/organization-profile",
  //       label: "Organization Profile",
  //       icon: <SettingOutlined />,
  //     },
  //     { key: "logout", label: "Logout", icon: <LogoutOutlined /> },
  //   ];

  //   return (
  //     <Menu
  //       direction="up"
  //       items={supportMenuItems.map((item) => ({
  //         key: item.key,
  //         icon: item.icon,
  //         label: item.label,
  //         onClick: handleMenuItemClick,
  //       }))}
  //       mode="inline"
  //       selectedKeys={[getActiveMenuItem()]}
  //       theme="light"
  //     />
  //   );
  // };

  const toggleSupportMenu = () => {
    setSupportMenuVisible(!supportMenuVisible);
  };

  // Replace renderSupportMenu with a new function to create the dropdown menu items
  const renderDropdownMenu = () => (
    <Menu style={{ marginLeft: 8 }} onClick={handleMenuItemClick}>
      <Menu.Item icon={<UserOutlined />} key="/user-profile">
        User Profile
      </Menu.Item>
      <Menu.Item icon={<SettingOutlined />} key="/organization-profile">
        Organization Profile
      </Menu.Item>
      <Menu.Item danger icon={<LogoutOutlined />} key="logout">
        Logout
      </Menu.Item>
    </Menu>
  );

  // Remove toggleSupportMenu function as it's not needed anymore
  // ...

  // Replace renderSupportCard with the updated component using Dropdown
  const renderSupportCard = () => (
    <>
      <Divider />
      <Dropdown
        overlay={renderDropdownMenu()}
        placement="topLeft"
        trigger={["click"]}
      >
        <div
          role="presentation"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start", // Align items to the start of the container
            padding: "10px", // Adjust padding if necessary
            background: "#fff",
            // boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
            borderTop: "1px solid #f0f0f0",
            cursor: "pointer",
            position: "absolute",
            bottom: "0",
            width: "100%",
          }}
          onClick={toggleSupportMenu}
        >
          <Avatar
            size={48} // Specify the size of the avatar
            src={user?.image || "path_to_default_image"}
            style={{
              minWidth: "48px", // Ensure the minimum width is the same as the size
              minHeight: "48px", // Ensure the minimum height is the same as the size
              marginRight: "12px",
            }}
          />
          <div style={{ flex: 1, fontSize: 14 }}>
            <strong>
              {user?.firstName || "User Name"} {user?.lastName || "User Name"}
            </strong>
            <p style={{ color: "grey" }}>{user?.role || "User Role"}</p>
          </div>
          {supportMenuVisible ? <CaretDownOutlined /> : <CaretUpOutlined />}
        </div>
      </Dropdown>
    </>
  );
  return (
    <>
      {/* Mobile Top Bar */}
      <Row
        align="middle"
        className="mobile-top-bar"
        justify="space-between"
        style={{
          backgroundColor: "white",
          padding: "0 16px",
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 1001,
        }}
      >
        <Col lg={0} xs={24}>
          <Header
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
              height: "auto",
              backgroundColor: "white",
            }}
          >
            <Row
              align="middle"
              justify="space-between"
              style={{ width: "100%" }}
            >
              <Col xs={20}>
                <Link style={{ color: "black", fontSize: "18px" }} to="/">
                  <img
                    alt="Preside Logo"
                    src={logoImagePath}
                    style={{ height: "32px" }}
                  />
                </Link>
              </Col>
              <Col style={{ textAlign: "right" }} xs={4}>
                <Button
                  style={{
                    color: "#black",
                    background: "none",
                    border: "none",
                  }}
                  type="text"
                  onClick={() => setDrawerVisible(true)}
                >
                  <MenuOutlined />
                </Button>
              </Col>
            </Row>
          </Header>
        </Col>
      </Row>

      <Drawer
        closable={false}
        open={drawerVisible}
        placement="left"
        style={{
          marginTop: 64,
          zIndex: 1000, // Adjust this value to match the height of your mobile top bar
        }}
        title="Menu"
        onClose={() => setDrawerVisible(false)}
      >
        <NavigationMenu
          config={menuConfig}
          handleMenuItemClick={handleMenuItemClick}
          mode="inline"
          selectedKey={getActiveMenuItem()}
          withIcons={true}
          withTitles={true}
        />
      </Drawer>

      {/* Sidebar */}
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        style={{
          overflowY: "auto",
          height: "100vh",
          position: "fixed",
          background: "#FFF",
          left: 0,
          border: "1px solid #f0f0f0",
        }}
        width={sideBarWidth}
        onBreakpoint={(broken) => {
          if (!broken) {
            setDrawerVisible(false);
          }
        }}
        onCollapse={onCollapse}
      >
        <div
          style={{
            padding: "24px",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <img
              alt="Preside Logo"
              src={logoImagePath}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Link>
        </div>
        <Divider style={{ margin: "0 0 16px 0" }} />
        <NavigationMenu
          config={{
            Navigation: menuConfig.Navigation, // Only include the Navigation part of the menuConfig
          }}
          handleMenuItemClick={handleMenuItemClick}
          mode="inline"
          selectedKey={getActiveMenuItem()}
          withIcons={true}
          withTitles={true}
        />
        {/* <div style={{ flex: 1 }}></div> */}

        <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
          {renderSupportCard()}
        </div>
      </Sider>
    </>
  );
};

export default AppSidebar;
