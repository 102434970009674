import React, { useCallback, useContext, useEffect, useState } from "react";
import { Table, Tag, Tooltip, message } from "antd";
import { Link } from "react-router-dom";

import { UserContext } from "../contexts/UserContext";
import { getCheckoutHistoryByOrg } from "../services/assetService";

const CheckoutHistoryTable = ({ assetId, officerId }) => {
  const { user } = useContext(UserContext);
  const [checkoutHistory, setCheckoutHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAssets = useCallback(async () => {
    if (user) {
      setIsLoading(true);

      try {
        const rawData = await getCheckoutHistoryByOrg({
          organizationId: user.organizationId,
          assetId,
          officerId,
        });

        const processedData = rawData.map((event) => {
          const hasAsset = event.asset != null;
          const hasUser = event.user != null;
          const hasApprovedBy = event.approvedBy != null;

          return {
            id: event.id,
            type: event.type,
            timestamp: event.timestamp,
            assetId: hasAsset ? event.asset.id : "N/A",
            assetName: hasAsset ? event.asset.name : "N/A",
            assetCondition: hasAsset ? event.asset.condition : "N/A",
            userId: hasUser ? event.user.id ?? event.user.uid : "N/A",
            officerName: hasUser
              ? `${event.user.firstName} ${event.user.lastName}`
              : "N/A",
            officerBadgeNumber: hasUser ? `#${event.user.badgeNumber}` : "N/A",
            approvedById: event.approvedById ?? "N/A",
            approvedByName: hasApprovedBy
              ? `${event.approvedBy.firstName} ${event.approvedBy.lastName}`
              : "N/A",
            reason: event.reason ?? "N/A",
          };
        });

        setCheckoutHistory(processedData);
      } catch (error) {
        message.error("Error fetching checkout history requests");
      } finally {
        setIsLoading(false);
      }
    }
  }, [assetId, officerId, user]);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  const colorMap = {
    CHECKOUT_REQUESTED: "orange",
    CHECKOUT: "blue",
    CHECKOUT_APPROVED: "green",
    CHECKOUT_DENIED: "red",
    CHECKIN: "purple",
    ASSIGNMENT: "cyan",
  };

  const generateFilters = (field) => {
    const uniqueValues = [
      ...new Set(
        checkoutHistory.map((checkoutHistory) => checkoutHistory[field]),
      ),
    ];
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      sorter: (a, b) => a.type.localeCompare(b.type),
      filters: generateFilters("type"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.type.toLowerCase().includes(value.toLowerCase()),
      render: (type, record) =>
        record.reason ? (
          <Tooltip title={record.reason}>
            <Tag color={colorMap[type]} style={{ cursor: "pointer" }}>
              {type}
            </Tag>
          </Tooltip>
        ) : (
          <Tag color={colorMap[type]}>{type}</Tag>
        ),
    },
    {
      title: "Officer",
      dataIndex: "officerName",
      key: "officerName",
      align: "center",
      sorter: (a, b) => a.officerName.localeCompare(b.officerName),
      filters: generateFilters("officerName"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.officerName.toLowerCase().includes(value.toLowerCase()),
      render: (officerName, record) => {
        return <Link to={`/officers/${record.userId}`}>{officerName}</Link>;
      },
    },
    {
      title: "Asset",
      dataIndex: "assetName",
      key: "assetName",
      align: "center",
      sorter: (a, b) => a.assetName.localeCompare(b.assetName),
      filters: generateFilters("assetName"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.assetName.toLowerCase().includes(value.toLowerCase()),
      render: (assetName, record) => {
        return <Link to={`/assets/${record.assetId}`}>{assetName}</Link>;
      },
    },
    {
      title: "Condition",
      dataIndex: "assetCondition",
      align: "center",
      key: "assetCondition",
      sorter: (a, b) => a.assetCondition.localeCompare(b.assetCondition),
      filters: generateFilters("assetCondition"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.assetCondition.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Approved By",
      dataIndex: "approvedByName",
      key: "approvedByName",
      align: "center",
      sorter: (a, b) => a.approvedBy.localeCompare(b.approvedBy),
      filters: generateFilters("approvedByName"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.approvedByName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.timestamp.seconds - b.timestamp.seconds,
      render: (timestamp) =>
        new Date(timestamp.seconds * 1000).toLocaleString(),
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={checkoutHistory}
      loading={isLoading}
      pagination={{
        pageSize: 8,
        position: ["bottomCenter"],
        style: { margin: "20px 0" },
      }}
      rowKey="id"
      scroll={{ x: "max-content" }}
    />
  );
};

export default CheckoutHistoryTable;
