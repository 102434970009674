import { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";

function useIsMobile(debounceMs = 100) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  // Define the function to be debounced inside useCallback
  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 576);
  }, []);

  // Apply debounce to the handleResize function
  const debouncedHandleResize = debounce(handleResize, debounceMs);

  useEffect(() => {
    // Call the debounced function to set the initial state
    debouncedHandleResize();

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      // Cancel any pending debounces on cleanup
      debouncedHandleResize.cancel();
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [debouncedHandleResize]); // Dependency on debouncedHandleResize

  return isMobile;
}

export default useIsMobile;
