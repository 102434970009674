import React from "react";
import { Modal } from "antd";

const ConfirmDeleteModal = ({ isVisible, onConfirm, onCancel }) => {
  return (
    <Modal
      open={isVisible}
      title="Confirm Deletion"
      onCancel={onCancel}
      onOk={onConfirm}
    >
      <p>Are you sure you want to delete this image?</p>
    </Modal>
  );
};

export default ConfirmDeleteModal;
