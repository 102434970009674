import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Card, notification } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((/*userCredential*/) => {
        navigate("/");
      })
      .catch((error) => {
        notification.error({
          message: "Login Failed",
          description: error.message,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          width: "300px",
          position: "relative",
          backgroundColor: "inherit",
        }}
      >
        <Form
          // initialValues={}
          name="login"
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input placeholder="Email" prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password placeholder="Password" prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              Log In
            </Button>
          </Form.Item>
          <Form.Item>
            Don&apos;t have an account? <Link to="/signup">Sign Up</Link>
          </Form.Item>
          <Form.Item>
            Forgot Password? <Link to="/password-reset">Reset</Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
