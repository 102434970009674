import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Space, Table, message } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";

import { UserContext } from "../contexts/UserContext";
import { getOfficers } from "../services/officerService";

const OfficersTable = ({ currentTableView }) => {
  const { user } = useContext(UserContext);
  const [officers, setOfficers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchOfficers = useCallback(async () => {
    if (user) {
      setIsLoading(true);

      try {
        const officers = await getOfficers(user.organizationId);
        setOfficers(officers);
      } catch (error) {
        message.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchOfficers();
  }, [fetchOfficers, currentTableView]);

  const handleRowClick = (id) => {
    navigate(`/officers/${id}`);
  };

  const handleViewButtonClick = (event, id) => {
    event.stopPropagation();
    navigate(`/officers/${id}`);
  };

  const handleEditButtonClick = (event, id) => {
    event.stopPropagation();
    navigate(`/officers/${id}/edit`);
  };

  // const handleDeleteButtonClick = (event, id) => {
  //   event.stopPropagation();

  //   Modal.confirm({
  //     title: "Are you sure you want to delete this officer?",
  //     content:
  //       "This action cannot be undone and the officer will be permanently removed from the records.",
  //     onOk: async () => {
  //       try {
  //         await deleteOfficer(id);
  //         message.success("Officer deleted successfully");
  //         // Optionally, you can refresh the list of officers here, or navigate away.
  //         setOfficers(officers.filter((officer) => officer.id !== id));
  //       } catch (error) {
  //         message.error(`Error: ${error.message}`);
  //       }
  //     },
  //     onCancel() {
  //       console.log("Cancel delete");
  //     },
  //   });
  // };

  const generateFilters = (field) => {
    const uniqueValues = [
      ...new Set(officers.map((officer) => officer[field])),
    ];
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const generateColumnsWithDefaultSort = (currentTableView) => {
    const columns = [
      {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        align: "center",
        width: 185,
        render: (value, record) => (
          <Space direction="horizontal" size="small">
            <Button
              onClick={(event) => handleViewButtonClick(event, record.id)}
            >
              <EyeOutlined />
            </Button>
            <Button
              onClick={(event) => handleEditButtonClick(event, record.id)}
            >
              <EditOutlined />
            </Button>
            {/* <Button
              onClick={(event) => handleDeleteButtonClick(event, record.id)}
            >
              <DeleteOutlined />
            </Button> */}
          </Space>
        ),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        align: "center",
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        filters: generateFilters("firstName"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.firstName.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
        align: "center",
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        filters: generateFilters("lastName"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.lastName.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        align: "center",
        sorter: (a, b) => a.email.localeCompare(b.email),
        filters: generateFilters("email"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.email.toLowerCase().includes(value.toLowerCase()),
        render: (email) => {
          return <Link to={`mailto:${email}`}>{email}</Link>;
        },
      },
      {
        title: "Badge #",
        dataIndex: "badgeNumber",
        key: "badgeNumber",
        align: "center",
        sorter: (a, b) => a.badgeNumber.localeCompare(b.badgeNumber),
        filters: generateFilters("badgeNumber"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.badgeNumber.toLowerCase().includes(value.toLowerCase()),
        render: (badgeNumber) => `#${badgeNumber}`,
      },
      {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
        align: "center",
        sorter: (a, b) => a.rank.localeCompare(b.rank),
        filters: generateFilters("rank"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.rank.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: "Unit",
        dataIndex: "unit",
        key: "unit",
        align: "center",
        sorter: (a, b) => a.unit.localeCompare(b.unit),
        filters: generateFilters("unit"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.unit.toLowerCase().includes(value.toLowerCase()),
      },
    ];

    let columnIndex;
    if (currentTableView === "all") {
      columnIndex = columns.findIndex((column) => column.key === "lastName");
      columns[columnIndex]["defaultSortOrder"] = "ascend";
    } else if (currentTableView === "rank") {
      columnIndex = columns.findIndex((column) => column.key === "rank");
      columns[columnIndex]["defaultSortOrder"] = "ascend";
    } else if (currentTableView === "unit") {
      columnIndex = columns.findIndex((column) => column.key === "unit");
      columns[columnIndex]["defaultSortOrder"] = "ascend";
    }

    return columns;
  };

  return (
    <Table
      bordered
      columns={generateColumnsWithDefaultSort(currentTableView)}
      dataSource={officers}
      loading={isLoading}
      pagination={{
        pageSize: 8,
        position: ["bottomCenter"],
        style: { margin: "20px 0" },
      }}
      rowKey="id"
      scroll={{ x: "max-content" }}
      style={{ background: "#fff", margin: "24px" }}
      onRow={(record) => ({
        onClick: () => handleRowClick(record.id),
      })}
    />
  );
};

export default OfficersTable;
