import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import {
  Button,
  // Card,
  // Col,
  // Descriptions,
  Divider,
  Form,
  Input,
  Layout,
  // Row,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
  message,
} from "antd";
import { FunnelPlotOutlined } from "@ant-design/icons";

import { UserContext } from "../contexts/UserContext";
import Error from "./Error";
import SortByButton from "./SortByButton";
import CheckOutForm from "./CheckOutForm";
import AssignmentForm from "./AssignmentForm";
import AssetDetails from "./AssetDetails";
import CheckoutHistoryTable from "./CheckoutHistoryTable";
import CheckoutRequestsTable from "./CheckoutRequestsTable";
import {
  assignAsset,
  getAsset,
  checkinAsset,
  submitCheckoutRequestEvent,
} from "../services/assetService";

const { Content } = Layout;
const { Search } = Input;
// const { Text, Title } = Typography;
const { Title } = Typography;

// const descriptionItemStyle = {
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

const CheckoutAssetPage = () => {
  const [form] = Form.useForm();
  const [assignmentForm] = Form.useForm();
  const { assetId } = useParams();
  const { user } = useContext(UserContext);
  const [asset, setAsset] = useState(null);
  const [isloading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState("");
  const location = useLocation();
  const defaultTabKey = location.state?.tabKey || "assetDetails";

  useEffect(() => {
    const fetchAssetDetails = async () => {
      if (!asset && user) {
        try {
          const data = await getAsset(assetId);
          if (data.organizationId !== user.organizationId) {
            setErrorStatus("403");
          } else {
            setAsset(data);
            form.setFieldsValue({
              assetId: data.id,
            });
          }
        } catch (error) {
          setErrorStatus("404");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAssetDetails();
  }, [asset, assetId, form, user]);

  const handleCheckInButtonClick = async (formData) => {
    try {
      setIsLoading(true);

      await checkinAsset({
        ...formData,
        assetId,
      });

      message.success("Asset checked-in successfully.");

      const updatedAsset = await getAsset(assetId);
      setAsset(updatedAsset);
    } catch (error) {
      message.error(`Check-in failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckoutRequest = async (formData) => {
    try {
      setIsLoading(true);

      console.log(formData);

      const checkoutRequestData = {
        assetId,
        userId: formData.isCheckoutForSelf
          ? user.id ?? user.uid
          : formData.assignedOfficerId,
        organizationId: user.organizationId,
        barcode: formData.barcode,
        condition: formData.condition,
        type: "CHECKOUT_REQUESTED",
        checkoutDate: Timestamp.fromDate(new Date(formData.checkoutDate)),
        returnDate: Timestamp.fromDate(new Date(formData.returnDate)),
      };

      // Call the service method to submit the checkout request event
      await submitCheckoutRequestEvent(checkoutRequestData);
      message.success("Checkout request submitted successfully.");
    } catch (error) {
      message.error(`Checkout request failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignmentButtonClick = async (formData) => {
    try {
      setIsLoading(true);

      await assignAsset({
        ...formData,
        assetId,
        adminUserId: user.id ?? user.uid, //added this because the Seed Data adds 'id' as an attribute to each User Document, but when you create a user and organization through the Sign Up flow, we only have a uid on the user
      });

      message.success("Asset assigned successfully.");

      const updatedAsset = await getAsset(assetId);
      setAsset(updatedAsset);
    } catch (error) {
      message.error(`Asset assignment failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = (value, _e, info) =>
    console.log(`${info?.source}: ${value}`);

  // const renderAssetDetails = () => {
  //   if (!asset) {
  //     return null;
  //   }

  //   return asset.status === "Checked Out" ? (
  //     <Descriptions bordered column={1} layout="vertical">
  //       <Descriptions.Item label="Asset Status" style={descriptionItemStyle}>
  //         {asset.status}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Return Date" style={descriptionItemStyle}>
  //         {asset.returnDate.toDate().toLocaleDateString()}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Asset ID" style={descriptionItemStyle}>
  //         {asset.id}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Asset Name" style={descriptionItemStyle}>
  //         {asset.name}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Asset Category" style={descriptionItemStyle}>
  //         {asset.category}
  //       </Descriptions.Item>
  //       <Descriptions.Item
  //         label="Asset Description"
  //         style={descriptionItemStyle}
  //       >
  //         {asset.description}
  //       </Descriptions.Item>
  //       <Descriptions.Item
  //         label="Asset Manufacturer"
  //         style={descriptionItemStyle}
  //       >
  //         {asset.manufacturer}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Asset Barcode" style={descriptionItemStyle}>
  //         {asset.barcode}
  //       </Descriptions.Item>
  //     </Descriptions>
  //   ) : (
  //     <Descriptions bordered column={1} layout="vertical">
  //       <Descriptions.Item label="Asset Status" style={descriptionItemStyle}>
  //         {asset.status}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Asset ID" style={descriptionItemStyle}>
  //         {asset.id}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Asset Name" style={descriptionItemStyle}>
  //         {asset.name}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Asset Category" style={descriptionItemStyle}>
  //         {asset.category}
  //       </Descriptions.Item>
  //       <Descriptions.Item
  //         label="Asset Description"
  //         style={descriptionItemStyle}
  //       >
  //         {asset.description}
  //       </Descriptions.Item>
  //       <Descriptions.Item
  //         label="Asset Manufacturer"
  //         style={descriptionItemStyle}
  //       >
  //         {asset.manufacturer}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="Asset Barcode" style={descriptionItemStyle}>
  //         {asset.barcode}
  //       </Descriptions.Item>
  //     </Descriptions>
  //   );
  // };

  const generateTabs = () => {
    const tabs = [];

    if (
      asset &&
      (asset.status === "Checked Out" || asset.status === "Assigned")
    ) {
      tabs.push({
        label: "Checkin Asset",
        key: "checkinAsset",
        children: asset ? (
          <>
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Checkin Asset
              </Title>
              {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
            </Space>
            <Divider style={{ margin: "16px 0" }} />
            <Form
              form={form}
              layout="vertical"
              onFinish={handleCheckInButtonClick}
            >
              <Form.Item
                label="Barcode"
                name="barcode"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Condition"
                name="condition"
                rules={[{ required: true }]}
              >
                <Select placeholder="Asset condition at time of checkout">
                  <Select.Option value="New">New</Select.Option>
                  <Select.Option value="Good">Good</Select.Option>
                  <Select.Option value="Fair">Fair</Select.Option>
                  <Select.Option value="Poor">Poor</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Check-In Asset
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <Spin size="large" />
        ),
      });
    } else if (asset && asset.status === "Available") {
      tabs.push(
        {
          label: "Checkout Asset",
          key: "checkoutAsset",
          children: asset ? (
            <>
              <Space align="start" direction="vertical" size={1}>
                <Title level={5} style={{ marginBottom: "5px" }}>
                  Checkout Asset
                </Title>
                {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
              </Space>
              <Divider style={{ margin: "16px 0" }} />
              <CheckOutForm
                barcode={asset.barcode}
                form={form}
                handleCheckoutRequest={handleCheckoutRequest}
                organizationId={user.organizationId}
              />
            </>
          ) : (
            <Spin size="large" />
          ),
        },
        {
          label: "Assign Asset",
          key: "assignAsset",
          children: asset ? (
            <>
              <Space align="start" direction="vertical" size={1}>
                <Title level={5} style={{ marginBottom: "5px" }}>
                  Assign Asset
                </Title>
                {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
              </Space>
              <Divider style={{ margin: "16px 0" }} />
              <AssignmentForm
                barcode={asset.barcode}
                form={assignmentForm}
                handleAssignmentButtonClick={handleAssignmentButtonClick}
                organizationId={user.organizationId}
              />
            </>
          ) : (
            <Spin size="large" />
          ),
        },
      );
    }

    tabs.push(
      {
        label: "Asset Details",
        key: "assetDetails",
        children: asset ? (
          <>
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Asset Details
              </Title>
            </Space>
            <Divider style={{ margin: "16px 0" }} />
            <AssetDetails />
          </>
        ) : (
          <Spin size="large" />
        ),
      },
      {
        label: "Pending Requests",
        key: "pendingRequests",
        children: (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
                alignItems: "flex-start",
              }}
            >
              <Space align="start" direction="vertical" size={1}>
                <Title level={5} style={{ marginBottom: "5px" }}>
                  Pending Requests
                </Title>
                {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
              </Space>

              <Space size={12}>
                <Search
                  allowClear
                  placeholder="Search"
                  style={{ width: 200 }}
                  onSearch={onSearch}
                />
                <Button icon={<FunnelPlotOutlined />}>Filter</Button>
                <SortByButton />
              </Space>
            </div>
            <CheckoutRequestsTable assetId={assetId} />
          </>
        ),
      },
      {
        label: "Checkout History",
        key: "checkoutHistory",
        children: (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
                alignItems: "flex-start",
              }}
            >
              <Space align="start" direction="vertical" size={1}>
                <Title level={5} style={{ marginBottom: "5px" }}>
                  Checkout History
                </Title>
                {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
              </Space>

              <Space size={12}>
                <Search
                  allowClear
                  placeholder="Search"
                  style={{ width: 200 }}
                  onSearch={onSearch}
                />
                <Button icon={<FunnelPlotOutlined />}>Filter</Button>
                <SortByButton />
              </Space>
            </div>
            <CheckoutHistoryTable assetId={assetId} />
          </>
        ),
      },
    );
    console.log(tabs);
    return tabs;
  };

  const renderContent = () => {
    if (isloading) {
      return <Spin size="large" />;
    }

    if (errorStatus) {
      return <Error status={errorStatus} />;
    }

    if (!asset) {
      return <Error status="404" />;
    }

    return (
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey={defaultTabKey}
        items={generateTabs()}
      />
    );
  };

  return (
    <Content
      style={{ minHeight: "100%", background: "white", padding: "0 25px" }}
    >
      {renderContent()}
    </Content>
  );
};

export default CheckoutAssetPage;
