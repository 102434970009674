// Export an object with all the global constants
module.exports = {
  PAGE_CONTENT: {
    DASHBOARD: {
      TITLE: "Dashboard",
      SUBHEADING: "See your information quickly",
    },
    ASSETS: {
      TITLE: "Assets",
      SUBHEADING: "View and manage your organization's assets",
    },
    OFFICERS: {
      TITLE: "Officers",
      SUBHEADING: "View and manage your organization's officers",
      FALLBACK_PFP_URL:
        "https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/52-512.png",
    },
    CHECKOUTS: {
      TITLE: "Checkouts",
      SUBHEADING:
        "View, manage, and approve the checkout of your organization's assets",
    },
    MAINTENANCE: {
      TITLE: "Maintenance",
      SUBHEADING:
        "View and manage the maintenance of your organization's assets",
    },
    REPORTS: {
      TITLE: "Reports",
      SUBHEADING: "See your organization's reports quickly",
    },
    USER_PROFILE: {
      TITLE: "User Profile",
      SUBHEADING: "See and edit your user profile information",
    },
    ORGANIZATION_PROFILE: {
      TITLE: "Organization Profile",
      SUBHEADING: "View and manage your organization's profile",
    },
    // Existing PAGE_CONTENT
    FALLBACK_ERRORS: {
      404: {
        STATUS: "404",
        TITLE: "404 Not Found",
        SUBTITLE: "The requested page could not be found.",
        BACK_TEXT: "Return to Dashboard",
      },
      403: {
        STATUS: "403",
        TITLE: "403 Forbidden",
        SUBTITLE: "You don't have permission to access this page.",
        BACK_TEXT: "Return to Dashboard",
      },
      500: {
        STATUS: "500",
        TITLE: "500 Internal Server Error",
        SUBHEADING: "Oops! Something went wrong on our end.",
        // DETAILS:
        //   "We're experiencing some technical issues right now. This is not an issue with your request. Please try again later. If the problem persists, contact our support team.",
        BACK_TEXT: "Return to Dashboard",
      },
    },
  },
};
