import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { message } from "antd";

import CustomSpinner from "./CustomSpinner";
import { auth } from "../services/firebaseConfig"; // Adjust the path as necessary

const ProtectedRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <CustomSpinner />; // Or use a spinner
  }

  if (error) {
    message.error("An error occurred during authentication. Please try again!");
  }

  return user ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
