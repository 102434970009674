import {
  ref,
  getDownloadURL,
  getStorage,
  deleteObject,
  uploadBytes,
} from "firebase/storage";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  runTransaction,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";
import { message } from "antd";

import { db } from "./firebaseConfig";
import { fetchInChunks } from "../utils/firestoreUtils";
import { getUsersByIds } from "./userService";
import {
  generateAndCheckUniqueBarcode,
  generateAndUploadBarcodeImage,
} from "./barcodeService";

const assetCollectionRef = collection(db, "assets");

export const getAssets = async (organizationId) => {
  const orgAssetsQuery = query(
    assetCollectionRef,
    where("organizationId", "==", organizationId),
  );

  const orgAssetsSnapshot = await getDocs(orgAssetsQuery);

  const orgAssets = orgAssetsSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return orgAssets;
};

export async function getAssetsByIds(assetIds) {
  return await fetchInChunks("assets", assetIds);
}

export const getDeployedAssets = async (options) => {
  const { organizationId, officerId = null } = options;
  const assetHistoryEventsCollectionRef = collection(db, "assetHistoryEvents");

  try {
    const orgAssetsQuery = query(
      assetCollectionRef,
      where("organizationId", "==", organizationId),
      where("status", "in", ["Assigned", "Checked Out"]),
    );

    const orgAssetsSnapshot = await getDocs(orgAssetsQuery);

    const orgAssets = orgAssetsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const orgAssetsIds = orgAssets.map((asset) => asset.id);

    // From the assetHistoryEvents collection, get the most recent
    // event for each asset in the orgAssets array
    let mostRecentAssetSpecificEvents = await Promise.all(
      orgAssetsIds.map(async (assetId) => {
        const assetHistoryEventsQueries = [
          where("assetId", "==", assetId),
          orderBy("timestamp", "desc"),
          limit(1),
        ];

        if (officerId) {
          assetHistoryEventsQueries.push(where("userId", "==", officerId));
        }

        const assetHistoryEventsSnapshot = await getDocs(
          query(assetHistoryEventsCollectionRef, ...assetHistoryEventsQueries),
        );
        const assetHistoryEvents = assetHistoryEventsSnapshot.docs.map(
          (doc) => ({
            ...doc.data(),
            id: doc.id,
          }),
        );

        return assetHistoryEvents[0];
      }),
    );

    // Quick hack to filter out undefined values before product demo
    mostRecentAssetSpecificEvents = mostRecentAssetSpecificEvents.filter(
      (event) => event,
    );

    const userIds = [
      ...new Set(mostRecentAssetSpecificEvents.map((event) => event.userId)),
    ];
    const approvedByIds = [
      ...new Set(
        mostRecentAssetSpecificEvents
          .filter((event) => event.approvedById)
          .map((event) => event.approvedById),
      ),
    ];

    const usersArray = await getUsersByIds(userIds);
    const approvedByUsersArray = await getUsersByIds(approvedByIds);

    const users = usersArray.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});

    const assets = orgAssets.reduce((acc, asset) => {
      acc[asset.id] = asset;
      return acc;
    }, {});

    const approvedByUsers = approvedByUsersArray.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});

    return mostRecentAssetSpecificEvents.map((event) => ({
      ...event,
      user: users[event.userId],
      asset: assets[event.assetId],
      approvedBy: approvedByUsers[event.approvedById],
    }));
  } catch (error) {
    console.error("Error fetching deployed assets: ", error);
    throw error;
  }
};

export const getCheckedOutAssetsByOrg = async (organizationId) => {
  const orgAssetsQuery = query(
    assetCollectionRef,
    where("organizationId", "==", organizationId),
    where("status", "==", "Checked Out"),
  );

  const orgAssetsSnapshot = await getDocs(orgAssetsQuery);

  const orgAssets = orgAssetsSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return orgAssets;
};

export const assignAsset = async ({
  barcode,
  condition,
  assetId,
  officerUserId,
  adminUserId,
}) => {
  const assetDocRef = doc(db, "assets", assetId);
  const assetHistoryEventCollectionRef = collection(db, "assetHistoryEvents");
  console.log(
    "assignAsset",
    assetId,
    officerUserId,
    adminUserId,
    condition,
    barcode,
  );
  let organizationId;
  try {
    await runTransaction(db, async (transaction) => {
      // Check if the asset exists and its status
      const assetDoc = await transaction.get(assetDocRef);
      if (!assetDoc.exists()) {
        throw new Error("Asset not found");
      }
      const assetData = assetDoc.data();
      if (assetData.barcode !== barcode) {
        throw new Error("Barcode does not match asset");
      }
      if (assetData.status !== "Available") {
        throw new Error("Asset is not available");
      }
      organizationId = assetData.organizationId;

      // Perform read for other pending requests
      const pendingRequestsQuery = query(
        assetHistoryEventCollectionRef,
        where("assetId", "==", assetId),
        where("type", "==", "CHECKOUT_REQUESTED"),
        where("currentStatus", "==", "pending"),
      );
      const pendingRequestsSnapshot = await getDocs(pendingRequestsQuery);
      const pendingRequestsData = pendingRequestsSnapshot.docs.map(
        (docSnapshot) => ({
          id: docSnapshot.id,
          assetId: docSnapshot.data().assetId,
          userId: docSnapshot.data().userId,
          organizationId: docSnapshot.data().organizationId,
        }),
      );

      // Update the asset's status to 'Assigned'
      transaction.update(assetDocRef, {
        returnDate: null,
        status: "Assigned",
      });

      // Add a new asset history event for the assignment
      const assetAssignmentHistoryEventDocRef = doc(
        assetHistoryEventCollectionRef,
      );
      transaction.set(assetAssignmentHistoryEventDocRef, {
        type: "ASSIGNMENT",
        timestamp: Timestamp.now(),
        currentStatus: "assigned",
        condition,
        assetId,
        userId: officerUserId,
        organizationId,
        approvedById: adminUserId,
      });

      // TODO: Consider whether you need this checkout event based on your system's logic
      // const assetCheckoutEventDocRef = doc(assetHistoryEventCollectionRef);
      // transaction.set(assetCheckoutEventDocRef, {
      //   type: "CHECKOUT",
      //   timestamp: Timestamp.now(),
      //   checkoutDate: Timestamp.now(),
      //   currentStatus: "approved",
      //   condition,
      //   assetId,
      //   userId: officerUserId,
      //   organizationId,
      //   approvedById: adminUserId,
      // });
      console.log("here");
      // Auto-deny other pending checkout requests for the asset
      for (const { id, assetId, userId } of pendingRequestsData) {
        await denyCheckoutRequestCore(
          transaction,
          id,
          assetId,
          userId,
          adminUserId,
          organizationId,
          "An assignment for this same asset to another officer was made.",
        );
      }
    });

    message.success("Asset assigned successfully");
  } catch (error) {
    message.error("Transaction failed: " + error.message);
    throw error;
  }
};

export const checkinAsset = async ({ barcode, condition, assetId }) => {
  const assetDocRef = doc(db, "assets", assetId);

  let organizationId;
  try {
    // Check if the asset exists
    const assetDoc = await getDoc(assetDocRef);
    if (!assetDoc.exists()) {
      throw new Error("Asset not found");
    }
    // Check asset status and barcode
    const assetData = assetDoc.data();

    if (assetData.barcode !== barcode) {
      throw new Error("Barcode does not match asset");
    }

    if (assetData.status === "Available") {
      throw new Error("Asset is not checked out");
    }

    organizationId = assetData.organizationId;
  } catch (error) {
    message.error("Asset check-in failed: " + error.message);
    throw error;
  }

  let mostRecentEvent;
  try {
    // Query the most recent AssetHistoryEvents entry for this asset
    const assetHistoryEventsQuery = query(
      collection(db, "assetHistoryEvents"),
      where("assetId", "==", assetId),
      orderBy("timestamp", "desc"),
      limit(1),
    );
    const querySnapshot = await getDocs(assetHistoryEventsQuery);

    if (!querySnapshot.empty) {
      mostRecentEvent = querySnapshot.docs[0].data();
      console.log("mostRecentEvent", mostRecentEvent);
      if (mostRecentEvent.type === "CHECKIN") {
        throw new Error("Asset was already checked in recently");
      }
    }
  } catch (error) {
    message.error("History check failed: " + error.message);
    throw error;
  }

  try {
    await runTransaction(db, async (transaction) => {
      // Update the asset document
      transaction.update(assetDocRef, {
        returnDate: null,
        status: "Available",
      });

      // Add a new asset history event
      const assetHistoryEventDocRef = doc(collection(db, "assetHistoryEvents"));
      transaction.set(assetHistoryEventDocRef, {
        type: "CHECKIN",
        timestamp: new Date(),
        currentStatus: "approved",
        condition,
        assetId,
        userId: mostRecentEvent.userId,
        organizationId,
      });
    });
  } catch (error) {
    message.error("Transaction failed: " + error.message);
    throw error;
  }
};

export const getAsset = async (id) => {
  const assetDoc = doc(db, "assets", id);
  const assetSnap = await getDoc(assetDoc);
  if (assetSnap.exists()) {
    return { ...assetSnap.data(), id: assetSnap.id };
  } else {
    throw new Error("Asset not found");
  }
};

export const addAssets = async (assetData) => {
  const { quantity, serialNumbers, ...restOfAssetData } = assetData;
  const hasSerialNumber = serialNumbers && serialNumbers.length > 0;

  const assets = Array.from({ length: quantity }, (_, index) => {
    // Ensure a serial number is available for each asset
    if (hasSerialNumber && index >= serialNumbers.length) {
      throw new Error(
        "Not enough serial numbers provided for the quantity of assets",
      );
    }

    return {
      ...restOfAssetData,
      serialNumber: hasSerialNumber ? serialNumbers[index] : null,
      status: "Available",
    };
  });

  const uploadedImageRefs = [];

  try {
    // Generate and upload all barcode images
    for (const [index, asset] of assets.entries()) {
      const barcode = await generateAndCheckUniqueBarcode(
        index,
        assetCollectionRef,
      );
      const imageRef = await generateAndUploadBarcodeImage(barcode, storage);
      const barcodeImageURL = await getDownloadURL(imageRef);
      uploadedImageRefs.push(imageRef);
      asset.barcode = barcode;
      asset.barcodeImageURL = barcodeImageURL;
    }

    await runTransaction(assetCollectionRef.firestore, async (transaction) => {
      for (const asset of assets) {
        const newDocRef = doc(assetCollectionRef);
        transaction.set(newDocRef, asset);
      }
    });

    return assets;
  } catch (error) {
    console.error(error);

    // Rollback: Attempt to delete uploaded images from Firebase Storage
    for (const imageRef of uploadedImageRefs) {
      try {
        await deleteObject(imageRef);
      } catch (deleteError) {
        console.error("Failed to rollback image:", deleteError);
      }
    }

    throw new Error(
      "Failed to add assets with unique barcodes. Transaction rolled back.",
    );
  }
};

export const updateAsset = async (id, updatedAsset) => {
  const assetDoc = doc(db, "assets", id);
  return await updateDoc(assetDoc, updatedAsset);
};

export const deleteAsset = async (id) => {
  const assetDoc = doc(db, "assets", id);
  return await deleteDoc(assetDoc);
};

const storage = getStorage();

export const uploadImagesAndGetURLs = (fileList) => {
  const uploadPromises = fileList.map((fileItem) => {
    const file = fileItem.originFileObj;
    if (!file) {
      return Promise.reject(new Error("No file to upload."));
    }
    const storageRef = ref(storage, `images/${Date.now()}-${file.name}`);

    // Wrap the Firebase storage API calls in a new promise.
    return new Promise((resolve, reject) => {
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref).then(resolve).catch(reject);
        })
        .catch(reject);
    });
  });

  return Promise.all(uploadPromises).catch((error) => {
    console.error("Error uploading images: ", error);
    throw error; // Re-throw the error to be handled by the caller
  });
};

export const deleteImageFromStorage = async (imageRef) => {
  try {
    await deleteObject(imageRef); // Cast the deletion spell on the image reference
    console.log("Image banished successfully");
  } catch (error) {
    console.error("Error banishing image: ", error);
    throw new Error(error.message);
  }
};

export const getInventoryByOrg = async (organizationId) => {
  const orgAssetsQuery = query(
    assetCollectionRef,
    where("organizationId", "==", organizationId),
  );

  const orgAssetsSnapshot = await getDocs(orgAssetsQuery);

  const orgAssets = orgAssetsSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  const inventoryCount = orgAssets.reduce((acc, asset) => {
    const existingAsset = acc.find((item) => item.name === asset.name);
    if (existingAsset) {
      existingAsset.quantity += 1;
    } else {
      acc.push({
        name: asset.name,
        quantity: 1,
      });
    }
    return acc;
  }, []);

  return inventoryCount;
};

export const getInventoryBreakdownByOrg = async (organizationId) => {
  const statuses = ["Available", "Assigned", "Checked Out"];

  const orgAssetsQuery = query(
    assetCollectionRef,
    where("organizationId", "==", organizationId),
    where("status", "in", statuses),
  );

  const orgAssetsSnapshot = await getDocs(orgAssetsQuery);

  const inventoryCounts = {};

  orgAssetsSnapshot.docs.forEach((doc) => {
    const asset = doc.data();
    const assetName = asset.name;

    if (!inventoryCounts[assetName]) {
      inventoryCounts[assetName] = {
        id: doc.id,
        name: assetName,
        available: 0,
        assigned: 0,
        checkedOut: 0,
        total: 0,
      };
    }

    switch (asset.status) {
      case "Available":
        inventoryCounts[assetName].available += 1;
        inventoryCounts[assetName].total += 1;
        break;
      case "Assigned":
        inventoryCounts[assetName].assigned += 1;
        inventoryCounts[assetName].total += 1;
        break;
      case "Checked Out":
        inventoryCounts[assetName].checkedOut += 1;
        inventoryCounts[assetName].total += 1;
        break;
      // No default case needed since we're filtering statuses in the query
    }
  });

  return Object.values(inventoryCounts);
};

export const submitCheckoutRequestEvent = async (eventData) => {
  try {
    await addDoc(collection(db, "assetHistoryEvents"), {
      ...eventData,
      timestamp: Timestamp.now(),
      currentStatus: "pending",
    });
  } catch (error) {
    message.error("Error submitting checkout request event: ", error);
    throw error;
  }
};

// Note: assetId and officerId are optional params.
//       if provided, only requests for that asset will be returned
export const getPendingCheckoutRequests = async (options) => {
  const { organizationId, assetId = null, officerId = null } = options;

  try {
    const assetHistoryEventQueries = [
      where("organizationId", "==", organizationId),
      where("type", "==", "CHECKOUT_REQUESTED"),
      where("currentStatus", "==", "pending"),
    ];

    if (assetId) {
      assetHistoryEventQueries.push(where("assetId", "==", assetId));
    }

    if (officerId) {
      assetHistoryEventQueries.push(where("userId", "==", officerId));
    }

    const querySnapshot = await getDocs(
      query(collection(db, "assetHistoryEvents"), ...assetHistoryEventQueries),
    );
    const requests = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const userIds = [...new Set(requests.map((request) => request.userId))];
    const assetIds = [...new Set(requests.map((request) => request.assetId))];

    const usersArray = await getUsersByIds(userIds);
    const assetsArray = await getAssetsByIds(assetIds);

    const users = usersArray.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});

    const assets = assetsArray.reduce((acc, asset) => {
      acc[asset.id] = asset;
      return acc;
    }, {});

    return requests.map((request) => ({
      ...request,
      user: users[request.userId],
      asset: assets[request.assetId],
    }));
  } catch (error) {
    console.error("Error fetching checkout requests: ", error);
    throw error;
  }
};

// Core logic for denying a checkout request, transaction-friendly
const denyCheckoutRequestCore = async (
  transaction,
  requestId,
  assetId,
  userId,
  adminUserId,
  organizationId,
  reason,
) => {
  const checkoutDeniedEventRef = doc(collection(db, "assetHistoryEvents"));
  transaction.set(checkoutDeniedEventRef, {
    type: "CHECKOUT_DENIED",
    assetId: assetId,
    userId: userId,
    organizationId: organizationId,
    approvedById: adminUserId,
    timestamp: Timestamp.now(),
    currentStatus: "denied",
    reason,
  });

  // Update the original request's current status
  const requestDocRef = doc(db, "assetHistoryEvents", requestId);
  transaction.update(requestDocRef, {
    currentStatus: "denied",
    approvedById: adminUserId,
  });
};

// The original denyCheckoutRequest function, for independent use
export const denyCheckoutRequest = async (requestId, adminUserId, reason) => {
  try {
    await runTransaction(db, async (transaction) => {
      const requestDocRef = doc(db, "assetHistoryEvents", requestId);
      const requestDoc = await transaction.get(requestDocRef);

      if (!requestDoc.exists()) {
        throw new Error("Request not found");
      }

      const assetId = requestDoc.data().assetId;
      const userId = requestDoc.data().userId;
      const organizationId = requestDoc.data().organizationId;

      await denyCheckoutRequestCore(
        transaction,
        requestId,
        assetId,
        userId,
        adminUserId,
        organizationId,
        reason,
      );
    });

    message.success("Checkout request denied");
  } catch (error) {
    message.error("Failed to deny checkout request: " + error.message);
  }
};

export const approveCheckoutRequest = async (requestId, adminUserId) => {
  const requestDocRef = doc(db, "assetHistoryEvents", requestId);

  try {
    await runTransaction(db, async (transaction) => {
      // Read the original request document
      const requestDoc = await transaction.get(requestDocRef);
      if (!requestDoc.exists()) {
        throw new Error("Request not found");
      }

      const assetId = requestDoc.data().assetId;
      const userId = requestDoc.data().userId;
      const organizationId = requestDoc.data().organizationId;
      const returnDate = requestDoc.data().returnDate;

      // Perform read for other pending requests
      const otherRequestsQuery = query(
        collection(db, "assetHistoryEvents"),
        where("assetId", "==", assetId),
        where("type", "==", "CHECKOUT_REQUESTED"),
        where("currentStatus", "==", "pending"),
      );
      const otherRequestsSnapshot = await getDocs(otherRequestsQuery);
      const otherRequestsData = otherRequestsSnapshot.docs
        .filter((docSnapshot) => docSnapshot.id !== requestId)
        .map((docSnapshot) => ({
          id: docSnapshot.id,
          assetId: docSnapshot.data().assetId,
          userId: docSnapshot.data().userId,
          organizationId: docSnapshot.data().organizationId,
        }));

      // Create a CHECKOUT_APPROVED event
      const checkoutApprovedEventRef = doc(
        collection(db, "assetHistoryEvents"),
      );
      transaction.set(checkoutApprovedEventRef, {
        type: "CHECKOUT_APPROVED",
        assetId: assetId,
        userId: userId,
        organizationId: organizationId,
        approvedById: adminUserId,
        timestamp: Timestamp.now(),
        currentStatus: "approved",
      });

      // Update the original request's current status to 'approved'
      transaction.update(requestDocRef, {
        currentStatus: "approved",
      });

      // Create a CHECKOUT event
      const checkoutEventRef = doc(collection(db, "assetHistoryEvents"));
      transaction.set(checkoutEventRef, {
        type: "CHECKOUT",
        assetId: assetId,
        userId: userId,
        organizationId: organizationId,
        approvedById: adminUserId,
        currentStatus: "approved",
        timestamp: new Timestamp(Timestamp.now().seconds + 1, 0),
        // FIXME: This should not occur until officer's actual checkout date
        checkoutDate: new Timestamp(Timestamp.now().seconds + 1, 0),
        returnDate: requestDoc.data().returnDate,
      });

      // Update the asset's status to 'Checked Out'
      const assetDocRef = doc(db, "assets", assetId);
      transaction.update(assetDocRef, {
        status: "Checked Out",
        returnDate,
      });

      // Deny all other pending requests
      for (const { id, assetId, userId } of otherRequestsData) {
        await denyCheckoutRequestCore(
          transaction,
          id,
          assetId,
          userId,
          adminUserId,
          organizationId,
          "A different request for this same asset from another officer was approved.",
        );
      }
    });
  } catch (error) {
    console.error("Error approving checkout request: ", error);
    throw error;
  }
};

export const getCheckoutHistoryByOrg = async (options) => {
  const { organizationId, assetId = null, officerId = null } = options;
  const assetHistoryEventsCollectionRef = collection(db, "assetHistoryEvents");
  console.log("getCheckoutHistoryByOrg", options);

  const assetHistoryEventsQueries = [
    where("organizationId", "==", organizationId),
    orderBy("currentStatus"),
    orderBy("timestamp", "desc"),
  ];

  if (assetId) {
    assetHistoryEventsQueries.push(where("assetId", "==", assetId));
  }

  if (officerId) {
    assetHistoryEventsQueries.push(where("userId", "==", officerId));
  }

  try {
    const querySnapshot = await getDocs(
      query(assetHistoryEventsCollectionRef, ...assetHistoryEventsQueries),
    );
    const checkoutHistory = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const userIds = [
      ...new Set(checkoutHistory.map((history) => history.userId)),
    ];
    const assetIds = [
      ...new Set(checkoutHistory.map((history) => history.assetId)),
    ];
    const approvedByIds = [
      ...new Set(
        checkoutHistory
          .filter((history) => history.approvedById)
          .map((history) => history.approvedById),
      ),
    ];

    const usersArray = await getUsersByIds(userIds);
    const assetsArray = await getAssetsByIds(assetIds);
    const approvedByUsersArray = await getUsersByIds(approvedByIds);

    const users = usersArray.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});

    const assets = assetsArray.reduce((acc, asset) => {
      acc[asset.id] = asset;
      return acc;
    }, {});

    const approvedByUsers = approvedByUsersArray.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});

    return checkoutHistory.map((history) => ({
      ...history,
      user: users[history.userId],
      asset: assets[history.assetId],
      approvedBy: approvedByUsers[history.approvedById],
    }));
  } catch (error) {
    console.error("Error fetching checkout history: ", error);
    throw error;
  }
};

export const getAssignedAssetsByOfficer = async (officerId) => {
  const assetHistoryEventsCollectionRef = collection(db, "assetHistoryEvents");

  const assetHistoryEventsQuery = query(
    assetHistoryEventsCollectionRef,
    where("userId", "==", officerId),
    where("type", "==", "ASSIGNMENT"),
    where("currentStatus", "==", "assigned"),
    orderBy("timestamp", "desc"),
  );

  let querySnapshot;
  try {
    querySnapshot = await getDocs(assetHistoryEventsQuery);
  } catch (error) {
    console.error("Error fetching assigned assets: ", error);
    throw error;
  }

  const assignedAssets = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return assignedAssets;
};
