import React from "react";
import { Row, Col, Radio, Button } from "antd";
import PropTypes from "prop-types";

// import FilterDrawer from "./FilterDrawer";

const TableControls = ({
  handleAddButton,
  addButtonText,
  tableViews,
  currentTableView,
  // closeFilterDrawer,
  // isFilterDrawerVisible,
  // setIsFilterDrawerVisible,
  // handleFilterApply,
  // columns,
  // filterGroups,
  // setFilterGroups,
}) => {
  // const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState(false);

  return (
    <Row
      align="middle"
      gutter={[16, 16]}
      justify="space-between"
      style={{ padding: 24, background: "#fff" }}
    >
      {/* Group 1: Radio Buttons */}
      <Col lg={16} md={16} sm={24} xl={18} xs={24}>
        <Radio.Group defaultValue={currentTableView}>
          {Object.entries(tableViews).map(([key, { text, action, icon }]) => (
            <Radio.Button key={key} value={key} onClick={action}>
              {icon && <span style={{ marginRight: "8px" }}>{icon}</span>}
              {text}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Col>

      {/* Group 2: Filter and Add Buttons */}
      <Col
        lg={8}
        md={24}
        sm={24}
        style={{
          display: "flex",
          // justifyContent: "start",
          justifyContent: "end",
          alignItems: "center",
          height: "100%",
        }}
        xl={6}
        xs={24}
      >
        {/* <Button
          style={{ marginLeft: "8px" }}
          onClick={() => {
            console.log("hello");
            setIsFilterDrawerVisible(!isFilterDrawerVisible);
          }}
        >
          Filter
        </Button> */}
        <Button
          style={{ marginLeft: "8px", whiteSpace: "nowrap" }}
          type="primary"
          onClick={handleAddButton}
        >
          {addButtonText}
        </Button>
      </Col>
      <>
        {/* {isFilterDrawerVisible && (
          <FilterDrawer
            columns={columns}
            filterGroups={filterGroups}
            setFilterGroups={setFilterGroups}
            visible={isFilterDrawerVisible}
            onApplyFilters={handleFilterApply}
            onClose={closeFilterDrawer}
          />
        )} */}
      </>
    </Row>
  );
};

TableControls.propTypes = {
  handleAddButton: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  tableViews: PropTypes.objectOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      icon: PropTypes.node,
    }),
  ).isRequired,
  filterConditions: PropTypes.array,
  setFilterConditions: PropTypes.func,
  onApplyFilters: PropTypes.func,
  filterOptions: PropTypes.array,
};

export default TableControls;

// State for managing filter drawer visibility and filters
// const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState(false);
// const [filterConditions, setFilterConditions] = useState([]);

// Functions to show and hide the filter drawer
// const showFilterDrawer = () => setIsFilterDrawerVisible(true);
// const closeFilterDrawer = () => setIsFilterDrawerVisible(false);

// Function to update filters based on drawer input
// const handleFilterApply = (newFilters) => {
//   setFilterConditions(newFilters);
//   closeFilterDrawer(); // Close drawer after applying filters
// };

// const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState(false);
//   const [filterGroups, setFilterGroups] = useState([
//     // { id: uuidv4(), operator: "and", conditions: [], groups: [] },
//   ]);
//   // Close the filter drawer
//   const closeFilterDrawer = () => setIsFilterDrawerVisible(false);

//   // Function to update filter conditions
//   const handleFilterApply = (groupedConditions) => {
//     // Assuming `groupedConditions` is an array of groups with conditions array inside
//     setFilterConditions(groupedConditions);
//     // closeFilterDrawer();
//   };

//   // Helper function to evaluate a single condition against an officer
//   const evaluateCondition = (officer, condition) => {
//     const field = officer[condition.field];
//     const conditionValue = condition.value.toLowerCase();

//     switch (condition.operator) {
//       case "contains":
//         return field && field.toLowerCase().includes(conditionValue);
//       case "does not contain":
//         return field && !field.toLowerCase().includes(conditionValue);
//       default:
//         // Handle other operators if necessary
//         return true;
//     }
//   };

//   // Function to evaluate all conditions within a group using "AND" logic

//   // Update the evaluateGroup function to handle nested groups correctly
//   // Function to evaluate all conditions within a group using "AND" or "OR" logic
//   const evaluateGroup = useCallback((item, group) => {
//     // Evaluate all conditions in the group
//     const conditionResults = group.conditions.map((condition) =>
//       evaluateCondition(item, condition),
//     );

//     // Recursively evaluate nested groups, if any
//     const nestedGroupResults = group.groups.map((nestedGroup) =>
//       evaluateGroup(item, nestedGroup),
//     );

//     // Combine condition results and nested group results with the group's operator
//     const combinedResults = [...conditionResults, ...nestedGroupResults];
//     return group.operator === "and"
//       ? combinedResults.every(Boolean)
//       : combinedResults.some(Boolean);
//   }, []); // Add dependencies if there are any external dependencies used in evaluateGroup
