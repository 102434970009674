import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { ref, getStorage } from "firebase/storage";

import AssetDetailsForm from "./AssetDetailsForm";
import ImageUpload from "./ImageUpload";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import {
  updateAsset,
  deleteImageFromStorage,
  uploadImagesAndGetURLs,
} from "../services/assetService";

export const AssetInformation = ({
  asset,
  isEditMode,
  onEditToggle,
  refreshAssetDetails,
  imageFileList,
  setSelectedImageForDeletion,
  selectedImageForDeletion,
  setImageFileList,
  setIsEditMode,
}) => {
  const navigate = useNavigate();
  const [editedAsset, setEditedAsset] = useState({ ...asset });
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);

  const storage = getStorage();

  useEffect(() => {
    setEditedAsset({ ...asset });
  }, [asset]);

  const showDeleteConfirm = (file) => {
    if (imageFileList.length > 1) {
      setSelectedImageForDeletion(
        ref(storage, `images/${extractFilename(file.url)}`),
      );
      setIsDeleteConfirmVisible(true);
    } else {
      message.warning("Cannot delete the last image of the asset.");
    }
  };

  const handleCheckoutButtonClick = (tabKey) => {
    navigate(`/checkouts/${asset.id}`, { state: { tabKey } });
  };

  const extractFilename = (url) => {
    const regex = /images%2F([^?]+)\?/;
    const match = url.match(regex);
    return match ? decodeURIComponent(match[1]) : null;
  };

  // Now, we'll handle the deletion confirmation inside the modal's onOk:
  const confirmDelete = async () => {
    try {
      const filename = selectedImageForDeletion.fullPath.split("/").pop(); // Extract the filename from the fullPath
      const updatedImages = asset.images.filter(
        (url) => !url.includes(filename),
      );
      const updatedAsset = { ...editedAsset, images: updatedImages };

      // Update state and Firestore
      setEditedAsset(updatedAsset);
      // setImageFileList(updatedImages); // Update state
      await updateAsset(asset.id, { images: updatedImages });
      await deleteImageFromStorage(selectedImageForDeletion);
      setIsDeleteConfirmVisible(false); // Close the modal
      refreshAssetDetails();
    } catch (error) {
      message.error(`Failed to delete image: ${error.message}`);
    }
  };

  // This function should be invoked when the file's status is "done"
  // to carry out the upload to Firebase Storage and return the URL.
  const handleUpload = async (file) => {
    try {
      // We need to pass the file wrapped in an array since your function expects an array of files
      const newImageUrls = await uploadImagesAndGetURLs([file]);
      // Since we only uploaded one file, we can take the first URL from the array
      return newImageUrls[0];
    } catch (error) {
      throw new Error(error.message); // Propagate the error
    }
  };

  const handleImageChange = async ({ fileList }) => {
    // Filter out files that are already uploaded or marked for removal
    const unprocessedFiles = fileList.filter(
      (file) => !file.url && file.status !== "removed",
    );

    // Process new files for upload
    for (const file of unprocessedFiles) {
      if (file.originFileObj) {
        try {
          // Upload the file and get the URL
          const newImageUrl = await handleUpload(file);

          // Update the asset with the new image URL
          const updatedImages = [...asset.images, newImageUrl];
          await updateAsset(asset.id, { ...asset, images: updatedImages });

          // Update the fileList with the new image URL
          setImageFileList((prevList) =>
            prevList.map((f) =>
              f.uid === file.uid
                ? { ...f, url: newImageUrl, status: "done" }
                : f,
            ),
          );
          refreshAssetDetails();
          message.success("Image uploaded successfully");
        } catch (error) {
          // If there's an error during upload, remove the file from the fileList
          setImageFileList((prevList) =>
            prevList.filter((f) => f.uid !== file.uid),
          );
          message.error(`Failed to upload image: ${error.message}`);
        }
      }
    }
  };

  const handleRemove = async (file) => {
    // Convert file.url to file.path as needed here, then call showDeleteConfirm
    showDeleteConfirm(file);
  };

  const handleEditChange = (key, value) => {
    setEditedAsset({ ...editedAsset, [key]: value });
  };

  const handleSaveChanges = async () => {
    try {
      const fieldsToUpdate = Object.keys(editedAsset).reduce((acc, key) => {
        if (asset[key] !== editedAsset[key]) {
          acc[key] = editedAsset[key];
        }
        return acc;
      }, {});

      // Only update the fields that have changed
      await updateAsset(asset.id, fieldsToUpdate);
      message.success("Asset updated successfully.");
      onEditToggle();
      refreshAssetDetails(); // This should update the asset prop
    } catch (error) {
      message.error(`Error saving changes: ${error.message}`);
    }
  };

  return (
    <div className="asset-information">
      <AssetDetailsForm
        editedAsset={editedAsset}
        handleEditChange={handleEditChange}
        isEditMode={isEditMode}
      />
      {/* Include other components and logic as needed */}
      {isEditMode && (
        <Button
          style={{ marginTop: "16px", marginRight: "8px" }}
          type="primary"
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      )}
      {!isEditMode && (
        <>
          {asset.status === "Available" ? (
            <Button
              style={{ marginRight: "8px" }}
              onClick={() => handleCheckoutButtonClick("assignAsset")}
            >
              Assign Asset
            </Button>
          ) : null}

          {asset.status === "Checked Out" || asset.status === "Assigned" ? (
            <Button
              style={{ marginRight: "8px" }}
              onClick={() => handleCheckoutButtonClick("checkinAsset")}
            >
              Check-In Asset
            </Button>
          ) : (
            <Button
              style={{ marginRight: "8px" }}
              onClick={() => handleCheckoutButtonClick("checkoutAsset")}
            >
              Check-Out Asset
            </Button>
          )}
        </>
      )}
      <Button
        style={{ marginTop: "16px" }}
        type="primary"
        onClick={() => setIsEditMode(!isEditMode)}
      >
        {isEditMode ? "Cancel Edit" : "Edit Asset"}
      </Button>
      {isEditMode && (
        <ImageUpload
          handleImageChange={handleImageChange}
          handleRemove={handleRemove}
          imageFileList={imageFileList}
        />
      )}
      <ConfirmDeleteModal
        isVisible={isDeleteConfirmVisible}
        onCancel={() => setIsDeleteConfirmVisible(false)}
        onConfirm={confirmDelete}
      />
    </div>
  );
};
