import React, { useState, useEffect } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";

import { getOfficers } from "../services/officerService";
import CustomSpinner from "./CustomSpinner";

const { Option } = Select;

const AssignmentForm = ({
  form,
  handleAssignmentButtonClick,
  organizationId,
  barcode,
}) => {
  const [officers, setOfficers] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOfficers = async () => {
      setLoading(true);
      try {
        const officerList = await getOfficers(organizationId);
        setOfficers(officerList);
      } catch (err) {
        // setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOfficers();
  }, [organizationId]);

  //TODO: DRY with this Select bar for officer selection with the checkoutform. refactor in future
  return (
    <Form
      form={form}
      initialValues={{
        barcode: barcode, // Set the initial value for the barcode field here
      }}
      layout="vertical"
      onFinish={handleAssignmentButtonClick}
    >
      <Form.Item label="Officer Name" name="officerUserId">
        <Select
          showSearch
          filterOption={(input, option) =>
            officers
              .find((officer) => officer.id === option.value)
              .firstName.toLowerCase()
              .includes(input.toLowerCase()) ||
            officers
              .find((officer) => officer.id === option.value)
              .lastName.toLowerCase()
              .includes(input.toLowerCase())
          }
          loading={loading}
          notFoundContent={loading ? <CustomSpinner /> : "No officers found"}
          placeholder="Select an Officer"
        >
          {officers.map((officer) => (
            <Option key={officer.id} value={officer.id}>
              {officer.firstName} {officer.lastName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Checkout Date"
        name="checkoutDate"
        rules={[{ required: true }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Asset Barcode"
        name="barcode"
        rules={[{ required: true }]}
      >
        <Input placeholder="Scan or enter barcode" />
      </Form.Item>
      <Form.Item
        label="Condition"
        name="condition"
        rules={[{ required: true }]}
      >
        <Select placeholder="Asset condition at time of checkout">
          <Option value="New">New</Option>
          <Option value="Good">Good</Option>
          <Option value="Fair">Fair</Option>
          <Option value="Poor">Poor</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary">
          Assign Asset
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AssignmentForm;
