import React, { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";

import { PAGE_CONTENT } from "./const/global";
import AddAssetForm from "./components/AddAssetForm";
import AppSidebar from "./components/Sidebar";
import AssetPage from "./components/AssetsPage";
import AssetDetails from "./components/AssetDetails";
import AssetEditPage from "./components/AssetEditPage";
import AddOfficerPage from "./components/AddOfficerPage";
import CheckoutAssetPage from "./components/CheckoutAssetPage";
import CheckoutsPage from "./components/CheckoutsPage";
import Dashboard from "./components/Dashboard";
import Error from "./components/Error";
import Login from "./components/Login";
import MaintenancePage from "./components/MaintenancePage";
import OfficerDetailsPage from "./components/OfficerDetailsPage";
import OfficerEditPage from "./components/OfficerEditPage";
import OfficersPage from "./components/OfficersPage";
import OrganizationProfilePage from "./components/OrganizationProfilePage";
import PasswordReset from "./components/PasswordReset";
import ProtectedRoute from "./components/ProtectedRoute";
import ReportsPage from "./components/ReportsPage";
import SignUp from "./components/SignUp";
import UserProfilePage from "./components/UserProfilePage";
import UserProvider from "./contexts/UserContext";
import theme from "./styles/theme";
import PageHeader from "./components/PageHeader"; // Import the PageHeader component

import "antd/dist/reset.css";
import "./App.css";

const { Content } = Layout;

// Updated PrivateLayout component to accept pageTitle and pageSubheading props
const PrivateLayout = ({ children, pageTitle, pageSubheading }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const sideBarWidth = 200;

  return (
    <Layout className="App">
      <AppSidebar
        sideBarWidth={sideBarWidth}
        onCollapse={(collapsed) => setIsSidebarCollapsed(collapsed)}
      />
      <Layout
        style={{
          marginLeft: isSidebarCollapsed ? 0 : sideBarWidth,
          background: "#FFF",
        }}
      >
        {/* PageHeader is included here with the pageTitle and pageSubheading */}
        <PageHeader pageSubheading={pageSubheading} pageTitle={pageTitle} />
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: SignUp },
  { path: "/password-reset", component: PasswordReset },
];

// Incorporating PAGE_CONTENT into the protectedRoutes object
const protectedRoutes = [
  {
    path: "/",
    component: Dashboard,
    title: PAGE_CONTENT.DASHBOARD.TITLE,
    subheading: PAGE_CONTENT.DASHBOARD.SUBHEADING,
  },
  {
    path: "/assets/new",
    component: AddAssetForm,
    title: PAGE_CONTENT.ASSETS.TITLE,
    subheading: PAGE_CONTENT.ASSETS.SUBHEADING,
  },
  {
    path: "/assets/:assetId",
    component: AssetDetails,
    title: PAGE_CONTENT.ASSETS.TITLE,
    subheading: PAGE_CONTENT.ASSETS.SUBHEADING,
  },
  {
    path: "/assets/:assetId/edit",
    component: AssetEditPage,
    title: PAGE_CONTENT.ASSETS.TITLE,
    subheading: PAGE_CONTENT.ASSETS.SUBHEADING,
  },
  {
    path: "/assets",
    component: AssetPage,
    title: PAGE_CONTENT.ASSETS.TITLE,
    subheading: PAGE_CONTENT.ASSETS.SUBHEADING,
  },
  {
    path: "/officers/new",
    component: AddOfficerPage,
    title: PAGE_CONTENT.OFFICERS.TITLE,
    subheading: PAGE_CONTENT.OFFICERS.SUBHEADING,
  },
  {
    path: "/officers/:officerId",
    component: OfficerDetailsPage,
    title: PAGE_CONTENT.OFFICERS.TITLE,
    subheading: PAGE_CONTENT.OFFICERS.SUBHEADING,
  },
  {
    path: "/officers/:officerId/edit",
    component: OfficerEditPage,
    title: PAGE_CONTENT.OFFICERS.TITLE,
    subheading: PAGE_CONTENT.OFFICERS.SUBHEADING,
  },
  {
    path: "/officers",
    component: OfficersPage,
    title: PAGE_CONTENT.OFFICERS.TITLE,
    subheading: PAGE_CONTENT.OFFICERS.SUBHEADING,
  },
  {
    path: "/checkouts",
    component: CheckoutsPage,
    title: PAGE_CONTENT.CHECKOUTS.TITLE,
    subheading: PAGE_CONTENT.CHECKOUTS.SUBHEADING,
  },
  {
    path: "/checkouts/:assetId",
    component: CheckoutAssetPage,
    title: PAGE_CONTENT.CHECKOUTS.TITLE,
    subheading: PAGE_CONTENT.CHECKOUTS.SUBHEADING,
  },
  {
    path: "/maintenance",
    component: MaintenancePage,
    title: PAGE_CONTENT.MAINTENANCE.TITLE,
    subheading: PAGE_CONTENT.MAINTENANCE.SUBHEADING,
  },
  {
    path: "/reports",
    component: ReportsPage,
    title: PAGE_CONTENT.REPORTS.TITLE,
    subheading: PAGE_CONTENT.REPORTS.SUBHEADING,
  },
  {
    path: "/user-profile",
    component: UserProfilePage,
    title: PAGE_CONTENT.USER_PROFILE.TITLE,
    subheading: PAGE_CONTENT.USER_PROFILE.SUBHEADING,
  },
  {
    path: "/organization-profile",
    component: OrganizationProfilePage,
    title: PAGE_CONTENT.ORGANIZATION_PROFILE.TITLE,
    subheading: PAGE_CONTENT.ORGANIZATION_PROFILE.SUBHEADING,
  },
  // ... add additional routes as needed
];

const App = () => {
  return (
    <ConfigProvider theme={theme}>
      <UserProvider>
        <Router>
          <Routes>
            {/* Unprotected Routes */}
            {publicRoutes.map(({ path, component: Component }) => (
              <Route element={<Component />} key={path} path={path} />
            ))}

            {/* Protected Routes */}
            {protectedRoutes.map(
              ({ path, component: Component, title, subheading }) => (
                <Route
                  element={
                    <ProtectedRoute>
                      <PrivateLayout
                        pageSubheading={subheading}
                        pageTitle={title}
                      >
                        <Component />
                      </PrivateLayout>
                    </ProtectedRoute>
                  }
                  key={path}
                  path={path}
                />
              ),
            )}

            {/* Catch-all Route for 404 Not Found */}
            <Route element={<Error status="404" />} path="*" />
          </Routes>
        </Router>
      </UserProvider>
    </ConfigProvider>
  );
};

export default App;
