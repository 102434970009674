import React, { createContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

import { db } from "../services/firebaseConfig"; // Adjust the import path as necessary

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState({}); // User details cached in state
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // Check if we already have the user details cached in state
        if (!userDetails[firebaseUser.uid]) {
          // Fetch additional user details from Firestore
          const userRef = doc(db, "users", firebaseUser.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            // Cache the user details in state
            const userDetails = userSnap.data();
            setUserDetails((prevState) => ({
              ...prevState,
              [firebaseUser.uid]: userDetails,
            }));
            setUser({ uid: firebaseUser.uid, ...userDetails });
          }
        } else {
          // Use cached user details instead of fetching from Firestore
          setUser({ uid: firebaseUser.uid, ...userDetails[firebaseUser.uid] });
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth, userDetails]);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
