import React from "react";
import { Image } from "antd";

export const ThumbnailStrip = ({
  imageFileList,
  onThumbnailClick,
  currentSlide,
}) => (
  <div
    className="thumbnail-strip"
    style={{ display: "flex", overflowX: "auto", gap: "16px" }}
  >
    {imageFileList.map((file, index) => (
      <div
        className={`thumbnail ${index === currentSlide ? "active" : ""}`}
        key={file.uid}
        role="button"
        style={{
          width: "100px",
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        tabIndex={0}
        onClick={() => onThumbnailClick(index)}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            onThumbnailClick(index);
          }
        }}
      >
        <Image
          alt={`Thumbnail ${index}`}
          preview={false}
          src={file.url}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "cover", // or 'contain' to avoid cropping
          }}
        />
      </div>
    ))}
  </div>
);
