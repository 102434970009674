import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Upload,
  message,
  Divider,
  Layout,
  Radio,
  Select,
  Row,
  Col,
  Switch,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

import {
  getAssets,
  addAssets,
  uploadImagesAndGetURLs,
} from "../services/assetService";
import { UserContext } from "../contexts/UserContext";

const { Content } = Layout;
const { Dragger } = Upload;

const AddAssetForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [organizationId, setOrganizationId] = useState("");
  const [categories, setCategories] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [hasSerialNumber, setHasSerialNumber] = useState(false);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(0); // New state for quantity

  useEffect(() => {
    // Function to fetch categories
    const fetchCategories = async () => {
      // setLoading(true);
      try {
        const assets = await getAssets(organizationId);
        const uniqueCategories = [
          ...new Set(assets.map((asset) => asset.category)),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        message.error("Failed to fetch categories: " + error.message);
      } finally {
        // setLoading(false);
      }
    };

    if (organizationId) {
      fetchCategories();
    }
  }, [organizationId]);

  useEffect(() => {
    if (user && user.organizationId) {
      setOrganizationId(user.organizationId);
    }
  }, [user]);

  const onFinish = async (values) => {
    setIsSubmitting(true); // Start loading
    let imageUrls = [];
    if (values.images && values.images.length > 0) {
      imageUrls = await uploadImagesAndGetURLs(values.images);
    }

    // Extract serial numbers from values
    const serialNumbers = Object.keys(values)
      .filter((key) => key.startsWith("serialNumber"))
      .map((key) => values[key]);

    const assetData = {
      ...values,
      serialNumbers, // include serial numbers
      images: imageUrls,
      organizationId,
      global: false,
    };

    try {
      await addAssets(assetData);
      message.success("Asset added successfully");
      navigate("/assets");
    } catch (error) {
      message.error(`Error adding asset: ${error.message}`);
    } finally {
      setIsSubmitting(false); // Stop loading whether it's success or failure
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleCategoryChange = (value) => {
    if (value === "add-new-category" && inputValue.trim() !== "") {
      const newCategory = inputValue.trim();
      if (!categories.includes(newCategory)) {
        setCategories([...categories, newCategory]);
        form.setFieldsValue({ category: newCategory });
      }
    } else {
      form.setFieldsValue({ category: value });
    }
    setInputValue("");
  };

  const onSearch = (value) => {
    setInputValue(value);
  };

  // Simplify the handleQuantityChange function
  const handleQuantityChange = (e) => {
    const newQuantity = e.target.valueAsNumber || 0;
    setQuantity(newQuantity); // Update the quantity state

    setSerialNumbers((prev) =>
      [...Array(newQuantity)].map((_, i) => prev[i] || ""),
    );
  };

  const RequiredLabel = ({ label, index }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        marginRight: 8,
      }}
    >
      <div>
        <span style={{ color: "red", fontSize: 12 }}>* </span>
        {label} {index + 1}:
      </div>
    </div>
  );
  const renderSerialNumberInputs = () => {
    return (
      <div>
        {quantity > 1 && (
          <div style={{ marginBottom: "24px", fontWeight: "bold" }}>
            Please enter 1 serial number for every asset youre adding below
          </div>
        )}
        {serialNumbers.map((_, index) => (
          <Form.Item
            key={index}
            name={`serialNumber${index + 1}`}
            rules={[{ required: true, message: "Serial number required" }]}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <RequiredLabel index={index} label="Serial Number" />
              <Input
                placeholder={`Serial Number ${index + 1}`}
                style={{ flex: "auto" }}
              />
            </div>
          </Form.Item>
        ))}
      </div>
    );
  };

  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <Card
        bordered={false}
        style={{ margin: "0 auto", maxWidth: "992px" }}
        title="Add New Asset"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="High Risk Asset"
            name="isHighRisk"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Name of the asset" />
          </Form.Item>
          <Form.Item
            label="Category"
            name="category"
            rules={[
              { required: true, message: "Please select or add a category!" },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              notFoundContent={
                inputValue && !categories.includes(inputValue.trim()) ? (
                  <div
                    role="button"
                    style={{ padding: "10px", cursor: "pointer" }}
                    tabIndex={0}
                    onClick={() => handleCategoryChange("add-new-category")}
                  >
                    {`Add ${inputValue ? `"${inputValue}"` : "hello"}`}{" "}
                  </div>
                ) : null
              }
              placeholder="Select or add a category"
              onChange={handleCategoryChange}
              onSearch={onSearch}
            >
              {categories.map((category) => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Manufacturer"
            name="manufacturer"
            rules={[{ required: true }]}
          >
            <Input placeholder="Manufacturer of the asset" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true }]}
          >
            <Input placeholder="Brief description of the asset" />
          </Form.Item>
          <Form.Item
            label="Condition"
            name="condition"
            rules={[{ required: true }]}
          >
            <Select placeholder="Condition of the asset">
              <Select.Option value="New">New</Select.Option>
              <Select.Option value="Good">Good</Select.Option>
              <Select.Option value="Fair">Fair</Select.Option>
              <Select.Option value="Poor">Poor</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Cost" name="cost" rules={[{ required: true }]}>
            <Input min={0} placeholder="Cost of the asset" type="number" />
          </Form.Item>
          <Form.Item
            defaultValue={1}
            label="Quantity"
            name="quantity"
            rules={[{ required: true }]}
          >
            <Input
              min={1}
              placeholder="Assets must be in the same condition to add more than one!"
              type="number"
              onChange={handleQuantityChange}
            />
          </Form.Item>
          <Form.Item style={{}} valuePropName="checked">
            <Row align="middle" gutter={16}>
              <Col>
                <Switch
                  checked={hasSerialNumber}
                  id="hasSerialNumber"
                  onChange={(checked) => setHasSerialNumber(checked)}
                />
              </Col>
              <Col>
                <label htmlFor="hasSerialNumber">
                  Does this asset have a serial number?
                </label>
              </Col>
            </Row>
          </Form.Item>
          {hasSerialNumber && renderSerialNumberInputs()}
          <Form.Item
            getValueFromEvent={normFile}
            label="Images"
            name="images"
            rules={[
              { required: true, message: "Please upload at least one image!" },
            ]}
            valuePropName="fileList"
          >
            <Dragger
              accept="image/*"
              beforeUpload={() => false}
              multiple={true}
              name="images"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={isSubmitting} type="primary">
              Create Asset
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};

export default AddAssetForm;
