import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Layout, message, Divider } from "antd";

import OfficerForm from "./OfficerForm";
import { addOfficer } from "../services/officerService";
import { UserContext } from "../contexts/UserContext";

const { Content } = Layout;

const AddOfficerPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const handleSave = async (officerData) => {
    try {
      await addOfficer({
        ...officerData,
        organizationId: user.organizationId,
        role: "Officer",
      });
      message.success("Officer added successfully");
      navigate("/officers");
    } catch (error) {
      message.error(`Error adding officer: ${error.message}`);
    }
  };

  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <Card
        bordered={false}
        style={{ padding: "1rem", maxWidth: "992px" }}
        title="Add New Officer"
      >
        <OfficerForm
          onCancel={() => navigate("/officers")}
          onSave={handleSave}
        />
      </Card>
    </Content>
  );
};

export default AddOfficerPage;
