import React, { useState, useEffect, useContext } from "react";
import { Row, message, Avatar } from "antd";
import PropTypes from "prop-types";

import { getOrganizationProfile } from "../services/organizationService";
import { UserContext } from "../contexts/UserContext";
import useIsMobile from "../hooks/useIsMobile";

const PageHeader = ({ pageTitle, pageSubheading, icon }) => {
  const [topSpacing, setTopSpacing] = useState("1.5rem");
  const isMobile = useIsMobile();
  const { user } = useContext(UserContext);
  const [organizationData, setOrganizationData] = useState(null);

  // Fetch organization data
  useEffect(() => {
    if (user && user.organizationId) {
      const fetchOrganizationData = async () => {
        try {
          const data = await getOrganizationProfile(user.organizationId);
          setOrganizationData(data);
        } catch (error) {
          message.error("Failed to load organization data: " + error.message);
        }
      };

      fetchOrganizationData();
    }
  }, [user]);

  // Update top spacing
  useEffect(() => {
    const updateTopSpacing = () => {
      const mobileTopBar = document.querySelector(".mobile-top-bar");
      const mobileTopBarHeight = mobileTopBar ? mobileTopBar.offsetHeight : 0;
      const baseSpacing = 1.5; // Base spacing in rem
      const mobileSpacing = 1.0; // Adjusted spacing for mobile

      const newTopSpacing =
        mobileTopBarHeight > 0
          ? `${mobileTopBarHeight}px`
          : `${isMobile ? mobileSpacing : baseSpacing}rem`;

      setTopSpacing(newTopSpacing);
    };

    updateTopSpacing();
    window.addEventListener("resize", updateTopSpacing);

    return () => {
      window.removeEventListener("resize", updateTopSpacing);
    };
  }, [isMobile]);

  return (
    <Row
      align="middle"
      justify="start"
      style={{
        background: "#fff",
        padding: `${topSpacing} 20px 20px 
20px`,
        display: "flex",
      }}
    >
      <Avatar
        size={64}
        src={organizationData?.image || "path_to_default_image"}
        style={{ marginRight: "12px" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            marginBottom: "8px",
            fontSize: "28px",
            fontWeight: "bold",
          }}
        >
          {icon && <span style={{ marginRight: "8px" }}>{icon}</span>}
          {pageTitle}
        </h1>
        <p style={{ marginBottom: 0, fontSize: "14px" }}>{pageSubheading}</p>
      </div>
    </Row>
  );
};

PageHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageSubheading: PropTypes.string,
  icon: PropTypes.node,
};

export default PageHeader;
