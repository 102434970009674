import React from "react";
import { Result, Button, Space } from "antd";
import { useNavigate } from "react-router-dom";

import { PAGE_CONTENT } from "../const/global";

const { FALLBACK_ERRORS } = PAGE_CONTENT;

const isNonEmptyString = (str) => {
  return typeof str === "string" && str.length > 0;
};

const getErrorContentOrFallback = (status, title, subTitle, backText) => {
  let errorContent = FALLBACK_ERRORS[status];

  if (!errorContent) {
    errorContent = FALLBACK_ERRORS["500"];
  }

  if (isNonEmptyString(title)) {
    errorContent.TITLE = title;
  }

  if (isNonEmptyString(subTitle)) {
    errorContent.SUBTITLE = subTitle;
  }

  if (isNonEmptyString(backText)) {
    errorContent.BACK_TEXT = backText;
  }

  return errorContent;
};

const ErrorPage = ({
  status,
  title = null,
  subTitle = null,
  backText = null,
}) => {
  const navigate = useNavigate();

  const { STATUS, TITLE, SUBTITLE, BACK_TEXT } = getErrorContentOrFallback(
    status,
    title,
    subTitle,
    backText,
  );

  return (
    <Space
      direction="vertical"
      style={{ marginTop: "64px", width: "100%", justifyContent: "center" }}
    >
      <Result
        extra={[
          <Button key="goBack" type="primary" onClick={() => navigate("/")}>
            {BACK_TEXT}
          </Button>,
        ]}
        status={STATUS}
        subTitle={SUBTITLE}
        title={TITLE}
      />
    </Space>
  );
};

export default ErrorPage;
