import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, message } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { format } from "date-fns";

import DenyCheckoutRequestModal from "./DenyCheckoutRequestModal";
import {
  getPendingCheckoutRequests,
  approveCheckoutRequest,
  denyCheckoutRequest,
} from "../services/assetService";
import { UserContext } from "../contexts/UserContext";

const CheckoutRequestsTable = ({ assetId, officerId }) => {
  const { user } = useContext(UserContext);
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDenyModalVisible, setIsDenyModalVisible] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);

  const fetchRequests = useCallback(async () => {
    if (user) {
      setIsLoading(true);

      try {
        const rawData = await getPendingCheckoutRequests({
          organizationId: user.organizationId,
          assetId,
          officerId,
        });

        const processedData = rawData.map((request) => ({
          id: request.id,
          timestamp: request.timestamp,
          checkoutDate: request.checkoutDate,
          returnDate: request.returnDate,
          assetId: request.asset.id,
          assetName: request.asset.name,
          assetCondition: request.asset.condition,
          userId: request.user.id ?? request.user.uid,
          officerName: `${request.user.firstName} ${request.user.lastName}`,
          officerBadgeNumber: `#${request.user.badgeNumber}`,
        }));

        setRequests(processedData);
      } catch (error) {
        message.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user, assetId, officerId]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  const handleApprove = async (requestId) => {
    try {
      await approveCheckoutRequest(requestId, user.id ?? user.uid); //added this because the Seed Data adds 'id' as an attribute to each User Document, but when you create a user and organization through the Sign Up flow, we only have a uid on the user
      message.success("Checkout request approved");
      message.success("Asset checked out");
      message.success("All other checkout requests for this asset auto-denied");
    } catch (error) {
      message.error("Failed to approve checkout request");
    } finally {
      fetchRequests();
    }
  };

  const handleDeny = async (reason) => {
    try {
      await denyCheckoutRequest(selectedRequestId, user.id, reason);
      message.success("Checkout request denied");
    } catch (error) {
      message.error("Failed to deny checkout request");
    } finally {
      setIsDenyModalVisible(false);
      fetchRequests();
    }
  };

  const handleApproveButtonClick = (event, id) => {
    event.stopPropagation();
    handleApprove(id);
  };

  const handleDenyButtonClick = (event, id) => {
    event.stopPropagation();
    setSelectedRequestId(id);
    setIsDenyModalVisible(true);
  };

  const generateFilters = (field) => {
    const uniqueValues = [
      ...new Set(requests.map((requests) => requests[field])),
    ];
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const columns = [
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space direction="horizontal" size="small">
          <Button
            onClick={(event) => handleApproveButtonClick(event, record.id)}
          >
            <CheckCircleOutlined />
          </Button>
          <Button onClick={(event) => handleDenyButtonClick(event, record.id)}>
            <CloseCircleOutlined />
          </Button>
        </Space>
      ),
    },
    {
      title: "Officer",
      dataIndex: "officerName",
      align: "center",
      key: "officerName",
      sorter: (a, b) => a.assetName.localeCompare(b.assetName),
      filters: generateFilters("officerName"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.officerName.toLowerCase().includes(value.toLowerCase()),
      render: (officerName, record) => {
        return <Link to={`/officers/${record.userId}`}>{officerName}</Link>;
      },
    },
    {
      title: "Asset",
      dataIndex: "assetName",
      align: "center",
      key: "assetName",
      sorter: (a, b) => a.assetName.localeCompare(b.assetName),
      filters: generateFilters("assetName"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.assetName.toLowerCase().includes(value.toLowerCase()),
      render: (assetName, record) => {
        return <Link to={`/assets/${record.assetId}`}>{assetName}</Link>;
      },
    },
    {
      title: "Condition",
      dataIndex: "assetCondition",
      align: "center",
      key: "assetCondition",
      sorter: (a, b) => a.assetCondition.localeCompare(b.assetCondition),
      filters: generateFilters("assetCondition"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.assetCondition.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Checkout Date",
      dataIndex: "checkoutDate",
      align: "center",
      key: "checkoutDate",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.checkoutDate.seconds - b.checkoutDate.seconds,
      render: (checkoutDate) =>
        checkoutDate
          ? format(new Date(checkoutDate.seconds * 1000), "MM/dd/yyyy")
          : "N/A",
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
      align: "center",
      key: "returnDate",
      sorter: (a, b) => a.returnDate.seconds - b.returnDate.seconds,
      render: (returnDate) =>
        returnDate
          ? format(new Date(returnDate.seconds * 1000), "MM/dd/yyyy")
          : "N/A",
    },
    {
      title: "Request Submitted",
      dataIndex: "timestamp",
      align: "center",
      key: "timestamp",
      sorter: (a, b) => a.timestamp.seconds - b.timestamp.seconds,
      render: (timestamp) =>
        timestamp
          ? format(new Date(timestamp.seconds * 1000), "MM/dd/yyyy")
          : "N/A",
    },
    // {
    //   title: "BadgeNumber",
    //   dataIndex: "officerBadgeNumber",
    //   align: "center",
    //   key: "officerBadgeNumber",
    // },
  ];

  return (
    <>
      <Table
        bordered
        columns={columns}
        dataSource={requests}
        loading={isLoading}
        pagination={{
          pageSize: 8,
          position: ["bottomCenter"],
          style: { margin: "20px 0" },
        }}
        rowKey="id"
        scroll={{ x: "max-content" }}
        style={{ background: "#fff" }}
      />
      <DenyCheckoutRequestModal
        isVisible={isDenyModalVisible}
        onCancel={() => setIsDenyModalVisible(false)}
        onSubmit={handleDeny}
      />
    </>
  );
};

export default CheckoutRequestsTable;
