import React, { useState } from "react";
import { Layout, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { TeamOutlined, BankOutlined } from "@ant-design/icons";

import TableControls from "./TableControls";
import OfficersTable from "./OfficersTable";

const { Content } = Layout;

const OfficersPage = () => {
  const [currentTableView, setCurrentTableView] = useState("all");
  const navigate = useNavigate();

  const handleTableViewSelect = (tableView) => {
    if (currentTableView !== tableView) {
      setCurrentTableView(tableView);
    }
  };

  const tableViews = {
    all: {
      text: "All Officers",
      action: () => handleTableViewSelect("all"),
      icon: <TeamOutlined />,
    },
    rank: {
      text: "Officers By Rank",
      action: () => handleTableViewSelect("rank"),
      icon: <BankOutlined />,
    },
    department: {
      text: "Officers By Unit",
      action: () => handleTableViewSelect("unit"),
      icon: <BankOutlined />,
    },
  };

  const handleAddOfficer = () => {
    navigate("/officers/new");
  };

  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <TableControls
        addButtonText="Add Officer"
        currentTableView={currentTableView}
        handleAddButton={handleAddOfficer}
        tableViews={tableViews}
      />
      <Divider style={{ margin: 0 }} />
      <OfficersTable
        currentTableView={currentTableView}
        key={currentTableView}
      />
    </Content>
  );
};
export default OfficersPage;
