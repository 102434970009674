import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";

import { db } from "./firebaseConfig";

const usersCollectionRef = collection(db, "users");

export const getOfficersAdminOnly = async (organizationId, userRole) => {
  if (!["Admin", "Organization Admin", "Super Admin"].includes(userRole)) {
    throw new Error("Unauthorized access");
  }
  const officersQuery = query(
    usersCollectionRef,
    where("organizationId", "==", organizationId),
    where("role", "==", "Officer"),
  );

  const querySnapshot = await getDocs(officersQuery);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getOfficers = async (organizationId) => {
  const officersQuery = query(
    usersCollectionRef,
    where("organizationId", "==", organizationId),
    where("role", "==", "Officer"),
  );

  const querySnapshot = await getDocs(officersQuery);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getOfficer = async (officerId) => {
  const officerDocRef = doc(db, "users", officerId);
  const officerSnap = await getDoc(officerDocRef);

  if (officerSnap.exists()) {
    const officerData = officerSnap.data();

    if (officerData.role === "Officer") {
      return { id: officerSnap.id, ...officerData };
    } else {
      throw new Error(`Document with ID ${officerId} is not an officer`);
    }
  } else {
    throw new Error(`Officer with ID ${officerId} not found`);
  }
};

export const addOfficer = async (officer) => {
  return await addDoc(usersCollectionRef, officer);
};

export const updateOfficer = async (officerId, updatedOfficer) => {
  const officerDocRef = doc(db, "users", officerId);
  const officerSnap = await getDoc(officerDocRef);

  if (officerSnap.exists() && officerSnap.data().role === "Officer") {
    return await updateDoc(officerDocRef, updatedOfficer);
  } else {
    throw new Error(
      `Document with ID ${officerId} is not an officer or does not exist`,
    );
  }
};

export const deleteOfficer = async (officerId) => {
  const officerDocRef = doc(db, "users", officerId);
  const officerSnap = await getDoc(officerDocRef);

  if (officerSnap.exists() && officerSnap.data().role === "Officer") {
    return await deleteDoc(officerDocRef);
  } else {
    throw new Error(
      `Document with ID ${officerId} is not an officer or does not exist`,
    );
  }
};
