import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Card,
  Layout,
  Divider,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  ref,
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { UserContext } from "../contexts/UserContext";
import { getUserById, updateUserProfile } from "../services/userService";

const { Content } = Layout;

const UserProfilePage = () => {
  const { user } = useContext(UserContext);
  const [imageFileList, setImageFileList] = useState([]);
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.uid) {
        try {
          setLoading(true);
          const userData = await getUserById(user.uid);
          form.setFieldsValue(userData);
        } catch (error) {
          message.error("Failed to load user data: " + error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [user, form]);

  const handleUpload = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `user_images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        () => {
          // Optional: Handle upload progress
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        },
      );
    });
  };

  //TODO: handledetel image

  const handleImageChange = async (info) => {
    // Process new file for upload
    if (info.fileList.length > 0) {
      setLoading(true);
      const file = info.fileList[0].originFileObj;
      if (!file) {
        setLoading(false);
        return;
      }
      try {
        const newImageUrl = await handleUpload(file);
        setImageFileList([
          { uid: info.fileList[0].uid, url: newImageUrl, status: "done" },
        ]);
        form.setFieldsValue({ image: newImageUrl });
        message.success("Image uploaded successfully");
      } catch (error) {
        message.error(`Failed to upload image: ${error.message}`);
        setImageFileList([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const updatedValues = { ...values };
      if (imageFileList.length > 0 && imageFileList[0].status === "done") {
        updatedValues.image = imageFileList[0].url;
      }

      await updateUserProfile(user.uid, updatedValues);
      message.success("User profile updated successfully.");
      setIsEditMode(false);
    } catch (error) {
      message.error("Failed to update user profile: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  if (!user) {
    return <p>You must be logged in to view this page.</p>;
  }

  console.log("loading", loading);
  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <Card
        bordered={false}
        style={{ margin: "0 auto", maxWidth: "992px" }}
        title="User Profile"
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true }]}
          >
            {isEditMode ? (
              <Input />
            ) : (
              <span>{form.getFieldValue("firstName")}</span>
            )}
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true }]}
          >
            {isEditMode ? (
              <Input />
            ) : (
              <span>{form.getFieldValue("lastName")}</span>
            )}
          </Form.Item>
          <Form.Item
            label="Nickname"
            name="nickname"
            rules={[{ required: true }]}
          >
            {isEditMode ? (
              <Input />
            ) : (
              <span>{form.getFieldValue("nickname")}</span>
            )}
          </Form.Item>
          <Form.Item label="Email" name="email">
            <span>{form.getFieldValue("email")}</span>
          </Form.Item>
          <Form.Item label="Profile Image" name="image">
            {isEditMode ? (
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                fileList={imageFileList}
                listType="picture-card"
                onChange={handleImageChange}
              >
                {imageFileList.length < 1 && <UploadOutlined />}
              </Upload>
            ) : (
              <img
                alt="Profile"
                src={form.getFieldValue("image")}
                style={{ width: "100px" }}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="default" onClick={() => setIsEditMode(!isEditMode)}>
              {isEditMode ? "Cancel" : "Edit"}
            </Button>
            {isEditMode && (
              <Button
                htmlType="submit"
                loading={loading}
                style={{ marginLeft: "8px" }}
                type="primary"
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};

export default UserProfilePage;
