import React from "react";
import { Descriptions, Image, Input } from "antd";

const AssetDetailsForm = ({ editedAsset, isEditMode, handleEditChange }) => {
  return (
    <Descriptions bordered column={1} size="small">
      <Descriptions.Item label="Category">
        {isEditMode ? (
          <Input
            value={editedAsset.category}
            onChange={(e) => handleEditChange("category", e.target.value)}
          />
        ) : (
          editedAsset.category
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Description">
        {isEditMode ? (
          <Input
            value={editedAsset.description}
            onChange={(e) => handleEditChange("description", e.target.value)}
          />
        ) : (
          editedAsset.description
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Manufacturer">
        {isEditMode ? (
          <Input
            value={editedAsset.manufacturer}
            onChange={(e) => handleEditChange("manufacturer", e.target.value)}
          />
        ) : (
          editedAsset.manufacturer
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Status">{editedAsset.status}</Descriptions.Item>
      <Descriptions.Item label="Barcode #">
        {editedAsset.barcode}
      </Descriptions.Item>
      <Descriptions.Item label="Barcode">
        <Image
          alt={`Barcode Image ${editedAsset.barcode}`}
          preview={false}
          src={editedAsset.barcodeImageURL}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Serial Number">
        {editedAsset.serialNumber ? editedAsset.serialNumber : "N/A"}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default AssetDetailsForm;
