import { getDocs } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import bwipjs from "bwip-js";

// Generate a unique 11-digit number
export function generateUnique11DigitNumber(index, retryCount) {
  const uniquePart = Date.now().toString().slice(-6);
  const indexPart = (index + retryCount).toString().padStart(5, "0");
  return uniquePart + indexPart;
}

// Calculate UPC-A check digit
function calculateUPCACheckDigit(number) {
  const digits = number.split("").map(Number);
  let oddSum = 0;
  let evenSum = 0;

  // UPC-A: Odd positioned digits (excluding the last digit)
  for (let i = 0; i < 11; i += 2) {
    oddSum += digits[i];
  }

  // UPC-A: Even positioned digits
  for (let i = 1; i < 11; i += 2) {
    evenSum += digits[i];
  }

  // Calculate check digit
  const totalSum = oddSum * 3 + evenSum;
  const nextTen = Math.ceil(totalSum / 10) * 10;
  const checkDigit = nextTen - totalSum;

  return checkDigit.toString();
}

// Main Function for Adding Assets
export async function generateAndCheckUniqueBarcode(index, assetCollectionRef) {
  const MAX_RETRY_LIMIT = 5;
  let retryCount = 0;

  // Fetch all existing barcodes from the database once
  const existingBarcodes = new Set();
  const allDocs = await getDocs(assetCollectionRef);
  allDocs.forEach((doc) => existingBarcodes.add(doc.data().barcode));

  while (retryCount < MAX_RETRY_LIMIT) {
    const uniqueNumber = generateUnique11DigitNumber(index, retryCount);
    const checkDigit = calculateUPCACheckDigit(uniqueNumber);
    const barcode = `${uniqueNumber}${checkDigit}`;

    if (!existingBarcodes.has(barcode)) {
      return barcode; // Unique barcode found
    }

    retryCount++;
  }

  throw new Error(
    `Failed to generate a unique barcode after ${MAX_RETRY_LIMIT} retries`,
  );
}

export async function generateAndUploadBarcodeImage(barcode, storage) {
  // Generate SVG string
  const barcodeSVG = bwipjs.toSVG({
    bcid: "upca", // Barcode type
    text: barcode, // Text to encode
    scale: 3, // 3x scaling factor
    height: 20, // Bar height, in millimeters
    includetext: true, // Include human-readable text
  });

  // Convert SVG string to Blob
  const svgBlob = new Blob([barcodeSVG], { type: "image/svg+xml" });

  // Set the file path and name in Firebase Storage
  const storageRef = ref(storage, `barcodes/${barcode}.svg`);

  // Upload the Blob with the correct content type
  await uploadBytes(storageRef, svgBlob, { contentType: "image/svg+xml" });

  // Return the reference for potential rollback in assertService.addAsset
  return storageRef;
}
