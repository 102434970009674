import { chunk } from "lodash";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
} from "firebase/firestore";

import { db } from "../services/firebaseConfig";

export async function fetchInChunks(collectionName, ids) {
  const chunks = chunk(ids, 10);
  const result = [];

  for (const chunk of chunks) {
    const snapshot = await getDocs(
      query(collection(db, collectionName), where(documentId(), "in", chunk)),
    );
    snapshot.forEach((doc) => {
      result.push({ id: doc.id, ...doc.data() });
    });
  }

  return result;
}
