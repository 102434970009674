import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Tabs,
  message,
} from "antd";
import { jsPDF } from "jspdf";

import { UserContext } from "../contexts/UserContext";
import UnderConstruction from "./UnderConstruction";
import PreviousReports from "../services/PreviousReports";
import { getOfficersAdminOnly } from "../services/officerService";
import {
  getCheckoutHistoryByOrg,
  getDeployedAssets,
} from "../services/assetService";
import { addReport, getReports } from "../services/reportsService";

import "jspdf-autotable";

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ReportsFeature = () => {
  const { user } = useContext(UserContext);
  const [selectedReport, setSelectedReport] = useState(null);
  const [reports, setReports] = useState([]);
  const [officers, setOfficers] = useState([]);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOfficers = async () => {
      if (selectedReport === "Offboarding" && user) {
        setIsLoading(true);
        try {
          const fetchedOfficers = await getOfficersAdminOnly(
            user.organizationId,
            user.role,
          );

          setOfficers(fetchedOfficers);
        } catch (error) {
          message.error(`Error fetching officers: ${error.message}`);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchOfficers();
  }, [selectedReport, user]);

  const fetchReports = useCallback(async () => {
    if (user) {
      setIsLoading(true);
      try {
        const fetchedReports = await getReports(user.organizationId);
        setReports(fetchedReports);
      } catch (error) {
        message.error("Failed to fetch reports");
        console.error("Failed to fetch reports:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports, user]);

  const handleReportTypeChange = (value) => {
    setSelectedReport(value);
    form.resetFields(["officer", "dateRange"]);
  };

  const fetchImageAsBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  const generateReportOffboarding = async (officerId) => {
    try {
      const rawData = await getDeployedAssets({
        organizationId: user.organizationId,
        officerId,
      });

      const processedData = rawData
        .map((event) => ({
          type: event.type,
          timestamp: event.timestamp,
          assetId: event.asset.id,
          assetName: event.asset.name,
          assetCondition: event.asset.condition,
          assetImage: event.asset.images[0],
          officerId: event.user.id ?? event.user.uid,
          officerName: `${event.user.firstName} ${event.user.lastName}`,
          officerRank: event.user.rank,
          approvedById: event.approvedBy.id,
          approvedByName: `${event.approvedBy.firstName} ${event.approvedBy.lastName}`,
        }))
        .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16,
      });

      const presideImageUrl =
        "https://firebasestorage.googleapis.com/v0/b/alloc-f9afd.appspot.com/o/organization_images%2Ffilled_icon__preside.png?alt=media&token=29d78f5d-e725-4303-ad90-034fd513b064";
      const base64PresideImage = await fetchImageAsBase64(presideImageUrl);

      const orgImageUrl =
        "https://firebasestorage.googleapis.com/v0/b/alloc-f9afd.appspot.com/o/organization_images%2Fgardendale_police_logo.jpeg?alt=media&token=2942b52c-317f-4a54-a8b7-0d4564bf34e2";
      const base64OrgImage = await fetchImageAsBase64(orgImageUrl);

      // Add the image to the PDF
      doc.addImage(base64PresideImage, "PNG", 25, 25, 75, 75);
      doc.addImage(base64OrgImage, "JPEG", 495, 25, 75, 75);

      // Header
      doc.setFontSize(24);
      doc.text("Offboarding Report", 297.5, 70, {
        align: "center",
      });
      doc.setFontSize(14);
      doc.text(
        `${processedData[0].officerRank} ${processedData[0].officerName}`,
        297.5,
        100,
        {
          align: "center",
        },
      );

      doc.setFont("times", "italic");
      doc.setFontSize(10);

      doc.text(
        "     This report is tailored to provide a comprehensive summary of all current assets assigned to or checked out by an officer who is departing from their position within our police department. It meticulously catalogs each piece of equipment, tool, and vehicle under the responsibility of the officer, ensuring a clear and accountable process for the return of department assets. The primary objective of this document is to facilitate a smooth and efficient offboarding process, guaranteeing that all assets are promptly returned in good condition, thereby maintaining the department's operational integrity and readiness. By systematically tracking and managing the return of these assets, we uphold our commitment to resource stewardship and accountability, ensuring a seamless transition and continuous operational excellence within our police department.",
        40,
        130,
        {
          maxWidth: 520,
        },
      );

      doc.setFontSize(8);

      doc.autoTable({
        startY: 220,
        head: [["Asset", "Type", "Approved By", "Condition", "Timestamp"]],
        headStyles: {
          fillColor: "#52c41a",
          halign: "center",
          valign: "middle",
        },
        bodyStyles: { halign: "center", valign: "middle" },
        body: processedData.map((event) => [
          event.assetName,
          event.type
            .toLowerCase()
            .split("_")
            .map((w) => w[0].toUpperCase() + w.slice(1))
            .join(" "),
          event.approvedByName,
          event.assetCondition,
          new Date(event.timestamp.seconds * 1000).toLocaleString(),
        ]),
      });

      doc
        .save("offboarding-report.pdf", { returnPromise: true })
        .then((blob) => {
          const reportMetadata = {
            title: "Offboarding",
            type: "Offboarding",
          };
          addReport(blob, reportMetadata, user).then(() => {
            message.success("Report generated and uploaded successfully");
            fetchReports();
          });
        });
    } catch (error) {
      message.error("Failed to generate report");
      console.error("Failed to generate report:", error);
    } finally {
      message.success("Report generated successfully");
    }
  };

  const generateReportCheckoutHistory = async (dateRange) => {
    console.log(dateRange);
    try {
      const rawData = await getCheckoutHistoryByOrg({
        organizationId: user.organizationId,
      });

      const processedData = rawData
        .map((event) => ({
          id: event.id,
          type: event.type,
          timestamp: event.timestamp,
          assetId: event.asset.id,
          assetName: event.asset.name,
          assetCondition: event.asset.condition,
          userId: event.user.id ?? event.user.uid,
          officerName: `${event.user.firstName} ${event.user.lastName}`,
          officerBadgeNumber: `#${event.user.badgeNumber}`,
          approvedById: event.approvedById,
          approvedByName: event.approvedBy
            ? `${event.approvedBy.firstName} ${event.approvedBy.lastName}`
            : "N/A",
          reason: event.reason,
        }))
        .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16,
      });

      const presideImageUrl =
        "https://firebasestorage.googleapis.com/v0/b/alloc-f9afd.appspot.com/o/organization_images%2Ffilled_icon__preside.png?alt=media&token=29d78f5d-e725-4303-ad90-034fd513b064";
      const base64PresideImage = await fetchImageAsBase64(presideImageUrl);

      const orgImageUrl =
        "https://firebasestorage.googleapis.com/v0/b/alloc-f9afd.appspot.com/o/organization_images%2Fgardendale_police_logo.jpeg?alt=media&token=2942b52c-317f-4a54-a8b7-0d4564bf34e2";
      const base64OrgImage = await fetchImageAsBase64(orgImageUrl);

      // Add the image to the PDF
      doc.addImage(base64PresideImage, "PNG", 25, 25, 75, 75);
      doc.addImage(base64OrgImage, "JPEG", 495, 25, 75, 75);

      // Header
      doc.setFontSize(24);
      doc.text("Checkout History Report", 297.5, 70, {
        align: "center",
      });
      doc.setFontSize(14);
      doc.text("January 1, 2023 - February 1, 2023", 297.5, 100, {
        align: "center",
      });

      doc.setFont("times", "italic");
      doc.setFontSize(10);

      doc.text(
        "     This report provides a detailed overview of the assignment and checkout history of all assets within our police department, covering a wide range of equipment, tools, and vehicles allocated to our officers. It aims to ensure accountability, maintain operational readiness, and facilitate efficient asset management by meticulously tracking asset utilization over time. By offering insights into the patterns of asset allocation and usage, this comprehensive document supports strategic planning, enhances resource allocation decisions, and promotes transparency within the department. Through this report, we are committed to upholding the highest standards of asset management to support our officers in fulfilling their duties effectively and safely.",
        40,
        130,
        {
          maxWidth: 520,
        },
      );

      doc.setFontSize(8);

      doc.autoTable({
        startY: 210,
        head: [
          ["Type", "Officer", "Asset", "Condition", "Approved By", "Timestamp"],
        ],
        headStyles: {
          fillColor: "#52c41a",
          halign: "center",
          valign: "middle",
        },
        bodyStyles: { halign: "center", valign: "middle" },
        body: processedData.map((event) => [
          event.type
            .toLowerCase()
            .split("_")
            .map((w) => w[0].toUpperCase() + w.slice(1))
            .join(" "),
          event.officerName,
          event.assetName,
          event.assetCondition,
          event.approvedByName,
          new Date(event.timestamp.seconds * 1000).toLocaleString(),
        ]),
      });

      doc
        .save("checkout-history-report.pdf", { returnPromise: true })
        .then((blob) => {
          const reportMetadata = {
            title: "Checkout History",
            type: "Checkout History",
          };
          addReport(blob, reportMetadata, user).then(() => {
            message.success("Report generated and uploaded successfully");
            fetchReports();
          });
        });
    } catch (error) {
      message.error("Failed to generate report");
      console.error("Failed to generate report:", error);
    } finally {
      message.success("Report generated successfully");
    }
  };

  const handleRunReport = async (values) => {
    setIsLoading(true);
    try {
      if (values.reportType === "Offboarding") {
        await generateReportOffboarding(values.officer);
      } else if (values.reportType === "Checkout History") {
        await generateReportCheckoutHistory(values.dateRange);
      }
      message.success("Report generated successfully");
    } catch (errorInfo) {
      message.error(
        "Failed to generate report. Please check the form for errors.",
      );
      console.log("Failed:", errorInfo);
    } finally {
      setIsLoading(false);
      form.resetFields(["officer", "dateRange"]);
    }
  };

  return (
    <Row gutter={16} style={{ margin: "24px" }}>
      <Col span={12}>
        <Card
          style={{
            margin: "0 auto",
            maxWidth: "992px",
            minHeight: "500px",
          }}
          title="Previous Reports"
        >
          <PreviousReports fetchReports={fetchReports} reports={reports} />
        </Card>
      </Col>

      <Col span={12}>
        <Card
          style={{
            margin: "0 auto",
            maxWidth: "992px",
            minHeight: "500px",
          }}
        >
          <Tabs destroyInactiveTabPane defaultActiveKey="1">
            <TabPane key="1" tab="Run an Existing Report">
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <Spin size="large" />
                </div>
              ) : (
                <Form form={form} layout="vertical" onFinish={handleRunReport}>
                  <Form.Item
                    label="Report Template"
                    name="reportType"
                    rules={[
                      {
                        required: true,
                        message: "Please select a report type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a report"
                      onChange={handleReportTypeChange}
                    >
                      <Option value="Offboarding">Offboarding</Option>
                      <Option value="Checkout History">Checkout History</Option>
                    </Select>
                  </Form.Item>

                  {selectedReport === "Offboarding" && (
                    <Form.Item
                      label="Officer"
                      name="officer"
                      rules={[
                        {
                          required: true,
                          message: "Please select an officer!",
                        },
                      ]}
                    >
                      <Select placeholder="Select an officer">
                        {officers.map((officer) => (
                          <Option key={officer.id} value={officer.id}>
                            {`${officer.firstName} ${officer.lastName}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {selectedReport === "Checkout History" && (
                    <Form.Item
                      label="Date Range"
                      name="dateRange"
                      rules={[
                        {
                          type: "array",
                          required: true,
                          message: "Please select a date range!",
                        },
                      ]}
                    >
                      <RangePicker />
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      Run Report
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </TabPane>
            <TabPane key="2" tab="Design a Custom Report">
              <UnderConstruction />
            </TabPane>
          </Tabs>
        </Card>
      </Col>
    </Row>
  );
};

export default ReportsFeature;
