import React from "react";
import { Button } from "antd";
import { DownOutlined, SortAscendingOutlined } from "@ant-design/icons";

const SortByButton = () => (
  <div className="select-with-icon">
    <SortAscendingOutlined className="select-icon" />
    <Button className="select-box" placeholder="Sort By">
      <span style={{ paddingLeft: "15px" }}>Sort By</span>
      <DownOutlined style={{ fontSize: "10px" }} />
    </Button>
  </div>
);

export default SortByButton;
