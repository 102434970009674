import React from "react";
import { Button, Input, Layout, Space, Tabs, Typography } from "antd";
import { FunnelPlotOutlined } from "@ant-design/icons";

// import CheckOutForm from "./CheckOutForm";
// import AssignmentForm from "./AssignmentForm";
// import AssetDetails from "./AssetDetails";
import CheckoutRequestsTable from "./CheckoutRequestsTable";
import DeployedAssetsTable from "./DeployedAssetsTable";
import CheckoutHistoryTable from "./CheckoutHistoryTable";
// import TableControls from "./TableControls";
import SortByButton from "./SortByButton";

const { Content } = Layout;
const { Search } = Input;
// const { Text, Title } = Typography;
const { Title } = Typography;

const CheckoutsPage = () => {
  // const [currentTableView, setCurrentTableView] = useState("pending");

  const onSearch = (value, _e, info) =>
    console.log(`${info?.source}: ${value}`);

  const tabs = [
    // {
    //   label: "Checkout Asset",
    //   key: "checkoutAsset",
    //   children: (
    //     <>
    //       <Space align="start" direction="vertical" size={1}>
    //         <Title level={5} style={{ marginBottom: "5px" }}>
    //           Checkout Asset
    //         </Title>
    //         <Text>Lorem Ipsum dolor sit amet</Text>
    //       </Space>
    //       <Divider style={{ margin: "16px 0" }} />
    //       <CheckOutForm />
    //     </>
    //   ),
    // },
    // {
    //   label: "Assign Asset",
    //   key: "assignAsset",
    //   children: (
    //     <>
    //       <Space align="start" direction="vertical" size={1}>
    //         <Title level={5} style={{ marginBottom: "5px" }}>
    //           Assign Asset
    //         </Title>
    //         <Text>Lorem Ipsum dolor sit amet</Text>
    //       </Space>
    //       <Divider style={{ margin: "16px 0" }} />
    //       <AssignmentForm />
    //     </>
    //   ),
    // },
    // {
    //   label: "Asset Details",
    //   key: "assetDetails",
    //   children: (
    //     <>
    //       <Space align="start" direction="vertical" size={1}>
    //         <Title level={5} style={{ marginBottom: "5px" }}>
    //           Asset Details
    //         </Title>
    //         <Text>Lorem Ipsum dolor sit amet</Text>
    //       </Space>
    //       <Divider style={{ margin: "16px 0" }} />
    //       {/* <AssetDetails /> */}
    //     </>
    //   ),
    // },
    {
      label: "Pending Requests",
      key: "pendingRequests",
      children: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              alignItems: "flex-start",
            }}
          >
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Pending Requests
              </Title>
              {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
            </Space>

            <Space size={12}>
              <Search
                allowClear
                placeholder="Search"
                style={{ width: 200 }}
                onSearch={onSearch}
              />
              <Button icon={<FunnelPlotOutlined />}>Filter</Button>
              <SortByButton />
            </Space>
          </div>
          <CheckoutRequestsTable />
        </>
      ),
    },
    {
      label: "Deployed Assets",
      key: "deployedAssets",
      children: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              alignItems: "flex-start",
            }}
          >
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Deployed Assets
              </Title>
              {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
            </Space>

            <Space size={12}>
              <Search
                allowClear
                placeholder="Search"
                style={{ width: 200 }}
                onSearch={onSearch}
              />
              <Button icon={<FunnelPlotOutlined />}>Filter</Button>
              <SortByButton />
            </Space>
          </div>
          <DeployedAssetsTable />
        </>
      ),
    },
    {
      label: "Checkout History",
      key: "checkoutHistory",
      children: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              alignItems: "flex-start",
            }}
          >
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Checkout History
              </Title>
              {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
            </Space>

            <Space size={12}>
              <Search
                allowClear
                placeholder="Search"
                style={{ width: 200 }}
                onSearch={onSearch}
              />
              <Button icon={<FunnelPlotOutlined />}>Filter</Button>
              <SortByButton />
            </Space>
          </div>
          <CheckoutHistoryTable />
        </>
      ),
    },
  ];

  return (
    <Content style={{ minHeight: "100%", padding: "0 25px" }}>
      <Tabs destroyInactiveTabPane defaultActiveKey="Details" items={tabs} />
    </Content>
  );
};

export default CheckoutsPage;
