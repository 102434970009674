import React, { useState } from "react";
import { Table, Button, Space, Spin } from "antd";
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";

import { deleteReport } from "../services/reportsService";

const PreviousReports = ({ fetchReports, reports }) => {
  const [loading, setLoading] = useState(false);
  console.log("reports: ", reports);

  const handleView = (url) => {
    window.open(url, "_blank");
  };

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "report.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = async (id, url) => {
    setLoading(true);
    try {
      await deleteReport(id, url);
      // setReports(reports.filter((report) => report.id !== id));
      await fetchReports();
    } catch (error) {
      console.error("Failed to delete report:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "Created Date",
      dataIndex: "generatedDate",
      key: "generatedDate",
      render: (generatedDate) =>
        generatedDate
          ? format(new Date(generatedDate.seconds * 1000), "MM/dd/yyyy")
          : "N/A",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record.url)}
          />
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleDownload(record.url)}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id, record.url)}
          />
        </Space>
      ),
    },
  ];

  return loading ? (
    <Spin />
  ) : (
    <Table columns={columns} dataSource={reports} rowKey="id" />
  );
};

export default PreviousReports;
