import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Layout, message, Row, Col } from "antd";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage";

import { getAsset } from "../services/assetService";
import { AssetCarousel } from "./AssetCarousel";
import { ThumbnailStrip } from "./ThumbnailStrip";
import { CarouselControls } from "./CarouselControls";
import { AssetInformation } from "./AssetInformation";
import "../styles/AssetDetails.css";

const { Content } = Layout;

const AssetDetails = () => {
  const { assetId } = useParams();
  const navigate = useNavigate();
  const [asset, setAsset] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [imageFileList, setImageFileList] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef();
  const storage = getStorage();

  const fetchAssetDetails = useCallback(async () => {
    try {
      const asset = await getAsset(assetId);
      setAsset(asset);

      if (asset.images) {
        const list =
          typeof asset.images === "string"
            ? asset.images.split(",")
            : asset.images;
        const fileList = list.map((url, index) => ({
          uid: -index - 1,
          name: `Image ${index + 1}`,
          status: "done",
          url: url.trim(),
        }));

        setImageFileList(fileList);
      }
    } catch (error) {
      message.error(`Error fetching asset details: ${error.message}`);
      navigate("/assets");
    }
  }, [assetId, navigate]);

  useEffect(() => {
    fetchAssetDetails();
  }, [fetchAssetDetails]);

  const handleEditToggle = async () => {
    if (!isEditMode) {
      await fetchAssetDetails();
    }
    setIsEditMode(!isEditMode);
  };

  const handleImageUpload = async (files) => {
    const uploadedImages = files.map(async (file) => {
      const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = await uploadBytesResumable(storageRef, file);
      return getDownloadURL(uploadTask.ref);
    });
    return Promise.all(uploadedImages);
  };

  const onThumbnailClick = (index) => {
    setCurrentSlide(index);
    carouselRef.current.goTo(index, false);
  };

  const onPrevClick = () => {
    const newIndex =
      (currentSlide - 1 + imageFileList.length) % imageFileList.length;
    setCurrentSlide(newIndex);
    carouselRef.current.prev();
  };

  const onNextClick = () => {
    const newIndex = (currentSlide + 1) % imageFileList.length;
    setCurrentSlide(newIndex);
    carouselRef.current.next();
  };

  const [selectedImageForDeletion, setSelectedImageForDeletion] =
    useState(null);

  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <div
        style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-start" }}
      >
        <div style={{ flexGrow: 1 }}>
          <Card
            bordered={false}
            style={{ maxWidth: "992px", margin: "0 auto" }}
            title="Asset Details"
          >
            {asset ? (
              <Row gutter={[24, 16]}>
                <Col lg={12} md={24}>
                  <AssetCarousel
                    carouselRef={carouselRef}
                    currentSlide={currentSlide}
                    imageFileList={imageFileList}
                  />
                  <ThumbnailStrip
                    currentSlide={currentSlide}
                    imageFileList={imageFileList}
                    onThumbnailClick={onThumbnailClick}
                  />
                  <CarouselControls
                    onNextClick={onNextClick}
                    onPrevClick={onPrevClick}
                  />
                </Col>
                <Col lg={12} md={24}>
                  <AssetInformation
                    asset={asset}
                    handleImageUpload={handleImageUpload}
                    imageFileList={imageFileList}
                    isEditMode={isEditMode}
                    refreshAssetDetails={fetchAssetDetails}
                    selectedImageForDeletion={selectedImageForDeletion}
                    setImageFileList={setImageFileList}
                    setIsEditMode={setIsEditMode}
                    setSelectedImageForDeletion={setSelectedImageForDeletion}
                    onEditToggle={handleEditToggle}
                  />
                </Col>
              </Row>
            ) : (
              <p>Loading asset details...</p>
            )}
          </Card>
        </div>
      </div>
    </Content>
  );
};

export default AssetDetails;
