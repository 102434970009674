import React from "react";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const ImageUpload = ({ imageFileList, handleImageChange, handleRemove }) => {
  return (
    <div style={{ marginTop: "2rem" }}>
      <Upload
        accept="image/*"
        beforeUpload={() => false}
        fileList={imageFileList}
        listType="picture-card"
        onChange={handleImageChange}
        onRemove={handleRemove}
      >
        {imageFileList.length < 8 && <UploadOutlined />}
      </Upload>
    </div>
  );
};

export default ImageUpload;
