import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, message, Tag /*Avatar*/ } from "antd";

import { UserContext } from "../contexts/UserContext";
import { getDeployedAssets } from "../services/assetService";

const DeployedAssetsTable = ({ officerId }) => {
  const { user } = useContext(UserContext);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAssets = useCallback(async () => {
    console.log(officerId);
    if (user) {
      setIsLoading(true);

      try {
        const rawData = await getDeployedAssets({
          organizationId: user.organizationId,
          officerId,
        });

        const processedData = rawData.map((event) => ({
          type: event.type,
          timestamp: event.timestamp,
          assetId: event.asset.id,
          assetName: event.asset.name,
          assetCondition: event.asset.condition,
          assetImage: event.asset.images[0],
          officerId: event.user?.id ?? event.user?.uid,
          officerName: `${event.user.firstName} ${event.user.lastName}`,
          approvedById: event.approvedBy?.id,
          approvedByName: `${event.approvedBy?.firstName} ${event.approvedBy?.lastName}`,
        }));

        setAssets(processedData);
      } catch (error) {
        message.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [officerId, user]);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  const generateFilters = (field) => {
    const uniqueValues = [...new Set(assets.map((assets) => assets[field]))];
    return uniqueValues.map((value) => ({ text: value, value }));
  };
  const colorMap = {
    CHECKOUT_REQUESTED: "orange",
    CHECKOUT: "blue",
    CHECKOUT_APPROVED: "green",
    CHECKOUT_DENIED: "red",
    CHECKIN: "purple",
    ASSIGNMENT: "cyan",
  };

  const columns = [
    // {
    //   title: "",
    //   dataIndex: "assetImage",
    //   key: "assetImage",
    //   align: "center",
    //   render: (assetImage, record) => (
    //     <Avatar
    //       size={48}
    //       src={assetImage || "path_to_default_image"}
    //       style={{
    //         minWidth: "48px", // Ensure the minimum width is the same as the size
    //         minHeight: "48px", // Ensure the minimum height is the same as the size
    //       }}
    //     />
    //   ),
    // },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      sorter: (a, b) => a.type.localeCompare(b.type),
      filters: generateFilters("type"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.type.toLowerCase().includes(value.toLowerCase()),
      render: (type, record) =>
        record.reason ? (
          <Tag color={colorMap[type]} style={{ cursor: "pointer" }}>
            {type}
          </Tag>
        ) : (
          <Tag color={colorMap[type]}>{type}</Tag>
        ),
    },
    {
      title: "Officer",
      dataIndex: "officerName",
      key: "officerName",
      align: "center",
      sorter: (a, b) => a.officerName.localeCompare(b.officerName),
      filters: generateFilters("officerName"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.officerName.toLowerCase().includes(value.toLowerCase()),
      render: (officerName, record) => {
        return <Link to={`/officers/${record.officerId}`}>{officerName}</Link>;
      },
    },
    {
      title: "Asset",
      dataIndex: "assetName",
      key: "assetName",
      align: "center",
      sorter: (a, b) => a.assetName.localeCompare(b.assetName),
      filters: generateFilters("assetName"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.assetName.toLowerCase().includes(value.toLowerCase()),
      render: (assetName, record) => {
        return <Link to={`/assets/${record.assetId}`}>{assetName}</Link>;
      },
    },
    {
      title: "Condition",
      dataIndex: "assetCondition",
      align: "center",
      key: "assetCondition",
      sorter: (a, b) => a.assetCondition.localeCompare(b.assetCondition),
      filters: generateFilters("assetCondition"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.assetCondition.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Approved By",
      dataIndex: "approvedByName",
      key: "approvedByName",
      align: "center",
      sorter: (a, b) => a.approvedBy.localeCompare(b.approvedBy),
      filters: generateFilters("approvedByName"),
      filterSearch: true,
      onFilter: (value, record) =>
        record.approvedByName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.timestamp.seconds - b.timestamp.seconds,
      render: (timestamp) =>
        timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : "N/A",
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={assets}
      loading={isLoading}
      pagination={{
        pageSize: 8,
        position: ["bottomCenter"],
        style: { margin: "20px 0" },
      }}
      rowKey={(record, index) => `${record.assetId}-${index}`}
      scroll={{ x: "max-content" }}
      style={{ background: "#fff", width: "100%" }}
    />
  );
};

export default DeployedAssetsTable;
