import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Space, Table, message, Tag, Avatar } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";

import { UserContext } from "../contexts/UserContext";
import { getAssets } from "../services/assetService";

const AssetsTable = ({ currentTableView }) => {
  const { user } = useContext(UserContext);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchAssets = useCallback(async () => {
    if (user) {
      setIsLoading(true);

      try {
        const assets = await getAssets(user.organizationId);
        setAssets(assets);
      } catch (error) {
        message.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets, currentTableView]);

  const getCategoryTagColor = (category) => {
    const categoryColors = {
      "non-lethal weapons": "gold",
      "tools and weapons": "green",
      "swat team": "purple",
      "office supplies": "cyan",
      "class a uniforms": "blue",
      others: "gray",
      "weapons and firearms": "red",
    };

    return categoryColors[category.toLowerCase()] || "gold";
  };

  const handleRowClick = (assetId) => {
    navigate(`/assets/${assetId}`);
  };

  const handleViewButtonClick = (event, id) => {
    event.stopPropagation();
    navigate(`/assets/${id}`);
  };

  const handleEditButtonClick = (event, id) => {
    event.stopPropagation();
    navigate(`/assets/${id}/edit`);
  };

  const generateFilters = (field) => {
    const uniqueValues = [...new Set(assets.map((assets) => assets[field]))];
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const generateColumnsWithDefaultSort = (currentTableView) => {
    const columns = [
      {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        align: "center",
        width: 125,
        render: (value, record) => (
          <Space direction="horizontal" size="small">
            <Button
              onClick={(event) => handleViewButtonClick(event, record.id)}
            >
              <EyeOutlined />
            </Button>
            <Button
              onClick={(event) => handleEditButtonClick(event, record.id)}
            >
              <EditOutlined />
            </Button>
          </Space>
        ),
      },
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        align: "center",
        render: (text, record) => (
          <Avatar
            size={48}
            src={record?.images[0] || "path_to_default_image"}
            style={{
              minWidth: "48px", // Ensure the minimum width is the same as the size
              minHeight: "48px", // Ensure the minimum height is the same as the size
            }}
          />
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
        sorter: (a, b) => a.name.localeCompare(b.name),
        filters: generateFilters("name"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.name.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        align: "center",
        render: (value) => (
          <Tag color={getCategoryTagColor(value)}>{value}</Tag>
        ),
        sorter: (a, b) => a.category.localeCompare(b.category),
        filters: generateFilters("category"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.category.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        sorter: (a, b) => a.status.localeCompare(b.status),
        filters: generateFilters("status"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.status.toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        align: "center",
      },
      {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
        align: "center",
        sorter: (a, b) => a.cost - b.cost,
        render: (value) => `$${value}`,
      },
      {
        title: "Manufacturer",
        dataIndex: "manufacturer",
        key: "manufacturer",
        align: "center",
        sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
        filters: generateFilters("manufacturer"),
        filterSearch: true,
        onFilter: (value, record) =>
          record.manufacturer.toLowerCase().includes(value.toLowerCase()),
      },
    ];

    let columnIndex;
    if (currentTableView === "all") {
      columnIndex = columns.findIndex((column) => column.key === "name");
      columns[columnIndex]["defaultSortOrder"] = "ascend";
    } else if (currentTableView === "category") {
      columnIndex = columns.findIndex((column) => column.key === "category");
      columns[columnIndex]["defaultSortOrder"] = "ascend";
    } else if (currentTableView === "status") {
      columnIndex = columns.findIndex((column) => column.key === "status");
      columns[columnIndex]["defaultSortOrder"] = "ascend";
    }

    return columns;
  };

  return (
    <Table
      bordered
      columns={generateColumnsWithDefaultSort(currentTableView)}
      dataSource={assets}
      loading={isLoading}
      pagination={{
        pageSize: 8,
        position: ["bottomCenter"],
        style: { margin: "20px 0" },
      }}
      rowKey="id"
      scroll={{ x: "max-content" }}
      style={{ background: "#fff", margin: "24px" }}
      onRow={(record) => ({
        onClick: () => handleRowClick(record.id),
      })}
    />
  );
};

export default AssetsTable;
