import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  // Form,
  Input,
  Layout,
  message,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import {
  FunnelPlotOutlined,
  MailOutlined,
  ArrowLeftOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { PAGE_CONTENT } from "../const/global";
import { getOfficer } from "../services/officerService";
import CheckoutRequestsTable from "./CheckoutRequestsTable";
import CheckoutHistoryTable from "./CheckoutHistoryTable";
import DeployedAssetsTable from "./DeployedAssetsTable";
import SortByButton from "./SortByButton";

const { Content } = Layout;
const { Search } = Input;
const { Text, Title } = Typography;
const { FALLBACK_PFP_URL } = PAGE_CONTENT.OFFICERS;

const OfficersDetailsPage = () => {
  const [officer, setOfficer] = useState(null);
  const { officerId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  console.log(isLoading);

  useEffect(() => {
    const fetchOfficerDetails = async () => {
      try {
        const officerData = await getOfficer(officerId);
        setOfficer(officerData);
      } catch (error) {
        message.error(`Error: ${error.message}`);
        navigate("/officers");
      } finally {
        setIsLoading(false);
      }
    };
    fetchOfficerDetails();
  }, [officerId, navigate]);

  if (!officer) {
    return (
      <Content style={{ minHeight: "100vh", background: "white" }}>
        <Divider style={{ margin: 0 }} />
        <p>No officer details available.</p>
      </Content>
    );
  }

  const handleEditButtonClick = () => {
    navigate(`/officers/${officerId}/edit`);
  };

  const onSearch = (value, _e, info) =>
    console.log(`${info?.source}: ${value}`);

  const tabs = [
    {
      label: "Details",
      key: "details",
      children: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              alignItems: "flex-start",
            }}
          >
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Details
              </Title>
              {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
            </Space>

            <Space size={12}>
              <Button icon={<EditOutlined />} onClick={handleEditButtonClick}>
                Edit Profile
              </Button>
            </Space>
          </div>

          <Divider />

          <Text strong>First Name</Text>
          <center>
            <Input
              readOnly
              defaultValue={officer.firstName}
              style={{ width: 500 }}
            />
          </center>
          <Text strong>Last Name</Text>
          <center>
            <Input
              readOnly
              defaultValue={officer.lastName}
              style={{ width: 500 }}
            />
          </center>

          <Divider />

          <Text strong>Badge Number</Text>
          <center>
            <Input
              readOnly
              defaultValue={`#${officer.badgeNumber}`}
              style={{ width: 500 }}
            />
          </center>

          <Divider />

          <Text strong>Rank</Text>
          <center>
            <Input
              readOnly
              defaultValue={officer.rank}
              style={{ width: 500 }}
            />
          </center>

          <Divider />

          <Text strong>Unit</Text>
          <center>
            <Input
              readOnly
              defaultValue={officer.unit}
              style={{ width: 500 }}
            />
          </center>

          <Divider />
        </>
      ),
    },
    {
      label: "Deployed Assets",
      key: "deployedAssets",
      children: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              alignItems: "flex-start",
            }}
          >
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Deployed Assets
              </Title>
              {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
            </Space>

            <Space size={12}>
              <Search
                allowClear
                placeholder="Search"
                style={{ width: 200 }}
                onSearch={onSearch}
              />
              <Button icon={<FunnelPlotOutlined />}>Filter</Button>
              <SortByButton />
            </Space>
          </div>
          <DeployedAssetsTable officerId={officerId} />
        </>
      ),
    },
    {
      label: "Pending Requests",
      key: "pendingRequests",
      children: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              alignItems: "flex-start",
            }}
          >
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Pending Requests
              </Title>
              {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
            </Space>

            <Space size={12}>
              <Search
                allowClear
                placeholder="Search"
                style={{ width: 200 }}
                onSearch={onSearch}
              />
              <Button icon={<FunnelPlotOutlined />}>Filter</Button>
              <SortByButton />
            </Space>
          </div>
          <CheckoutRequestsTable officerId={officerId} />
        </>
      ),
    },
    {
      label: "Checkout History",
      key: "checkoutHistory",
      children: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              alignItems: "flex-start",
            }}
          >
            <Space align="start" direction="vertical" size={1}>
              <Title level={5} style={{ marginBottom: "5px" }}>
                Checkout History
              </Title>
              {/* <Text>Lorem Ipsum dolor sit amet</Text> */}
            </Space>

            <Space size={12}>
              <Search
                allowClear
                placeholder="Search"
                style={{ width: 200 }}
                onSearch={onSearch}
              />
              <Button icon={<FunnelPlotOutlined />}>Filter</Button>
              <SortByButton />
            </Space>
          </div>
          <CheckoutHistoryTable officerId={officerId} />
        </>
      ),
    },
  ];

  return (
    <Content
      style={{ minHeight: "100%", background: "white", padding: "0 25px" }}
    >
      <Button
        icon={<ArrowLeftOutlined />}
        style={{
          border: "none",
          boxShadow: "none",
          margin: "16px 0",
          padding: 0,
        }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Space direction="vertical" size={10} style={{ width: "100%" }}>
        <Row wrap={false}>
          <Col>
            <Avatar
              size={80}
              src={officer.image || FALLBACK_PFP_URL}
              style={{ backgroundColor: "#fde3cf" }}
            />
          </Col>
          <Col flex="auto" style={{ marginLeft: 16 }}>
            <Space direction="vertical" size={3}>
              <Space direction="horizontal">
                <Title level={4}>
                  {`${officer.firstName} ${officer.lastName}`}
                </Title>
                <Tag color="blue" style={{ marginBottom: "6px" }}>
                  {officer.rank}
                </Tag>
              </Space>
              <Badge status="success" text="Active" />
              <Text>Badge #{officer.badgeNumber}</Text>
            </Space>
          </Col>
          <Col>
            <Button icon={<MailOutlined />} type="primary">
              Send Message
            </Button>
          </Col>
        </Row>

        <Tabs destroyInactiveTabPane defaultActiveKey="Details" items={tabs} />
      </Space>
    </Content>
  );
};

export default OfficersDetailsPage;
