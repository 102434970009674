import {
  getAuth,
  sendPasswordResetEmail as fbSendPasswordResetEmail,
  signOut,
} from "firebase/auth";

export const sendPasswordResetEmail = async (email) => {
  const auth = getAuth();
  await fbSendPasswordResetEmail(auth, email);
};

export const logoutUser = async () => {
  const auth = getAuth();
  await signOut(auth);
};
