import React from "react";
import { Image, Carousel } from "antd";

export const AssetCarousel = ({ imageFileList, carouselRef }) => (
  <Carousel adaptiveHeight={true} dots={false} ref={carouselRef}>
    {imageFileList.map((file) => (
      <div key={file.uid}>
        <Image alt={`Asset Image ${file.uid}`} src={file.url} />
      </div>
    ))}
  </Carousel>
);
