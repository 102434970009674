import React from "react";
import { Divider, Layout } from "antd";

import UnderConstruction from "./UnderConstruction";
import { PAGE_CONTENT } from "../const/global";

const { Content } = Layout;
const { TITLE } = PAGE_CONTENT.ASSETS;

const AssetEditPage = () => {
  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <UnderConstruction pageName={TITLE.toLowerCase()} />
    </Content>
  );
};

export default AssetEditPage;
