import React from "react";
import { Menu } from "antd";

const NavigationMenu = ({
  mode,
  selectedKey,
  handleMenuItemClick,
  config = {},
  withTitles = false,
  withIcons = false,
}) => {
  const createMenuItems = (items, withIcons) => {
    return items.map((item) => ({
      key: item.key,
      icon: withIcons ? item.icon : null,
      label: item.label,
    }));
  };

  const menuItems = Object.entries(config)
    .map(([title, items]) => {
      const itemGroup = createMenuItems(items, withIcons);
      return withTitles
        ? { type: "group", label: title, children: itemGroup }
        : itemGroup;
    })
    .flat();

  return (
    <Menu
      items={menuItems}
      mode={mode}
      selectedKeys={[selectedKey]}
      style={{ borderRight: 0 }}
      theme="light"
      onClick={handleMenuItemClick}
    />
  );
};

export default NavigationMenu;
