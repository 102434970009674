import React from "react";
import { Spin } from "antd";

import logoImagePath from "../transparent_icon_preside.png";

// Inline styles for the spinning animation and centering
const containerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh", // Full height of the viewport
};

const spinStyle = {
  animation: "spin 1.5s linear infinite",
  maxWidth: "72px", // Maximum width
  maxHeight: "72px", // Maximum height to maintain aspect ratio
  width: "auto", // Adjust width automatically to maintain aspect ratio
  height: "auto", // Adjust height automatically to maintain aspect ratio
};

// Inline keyframes for the spin animation
const keyframesStyle = `
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
`;

const CustomSpinner = () => (
  <>
    <style>{keyframesStyle}</style>
    <div style={containerStyle}>
      <Spin
        indicator={<img alt="Loading" src={logoImagePath} style={spinStyle} />}
      />
    </div>
  </>
);

export default CustomSpinner;
