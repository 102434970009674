// CarouselControls.js
import React from "react";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export const CarouselControls = ({ onPrevClick, onNextClick }) => {
  return (
    <Button.Group className="carousel-controls">
      <Button icon={<LeftOutlined />} onClick={onPrevClick} />
      <Button icon={<RightOutlined />} onClick={onNextClick} />
    </Button.Group>
  );
};
