import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";

import { db } from "./firebaseConfig";

const maintenanceHistoryEventsCollectionRef = collection(
  db,
  "maintenanceHistoryEvents",
);

const getStatus = (type) => {
  switch (type) {
    case "CONDITION_UPDATED_TO_REQUIRES_MAINTENANCE":
      return "Maintenance Required";
    case "MAINTENANCE_SCHEDULED":
      return "Scheduled";
    case "MAINTENANCE_PERFORMED":
      return "Performed";
    case "CONDITION_UPDATED_TO_GOOD":
      return "Condition Updated to Good";
    case "PLACED_BACK_INTO_SERVICE":
      return "Back in Service";
    default:
      return "Unknown";
  }
};

export const getMaintenanceHistoryByOrg = async (organizationId) => {
  //Get maintenance records for the organization
  const maintenanceHistoryQuery = query(
    maintenanceHistoryEventsCollectionRef,
    where("organizationId", "==", organizationId),
  );

  const maintenanceHistorySnapshot = await getDocs(maintenanceHistoryQuery);

  let maintenanceHistory = maintenanceHistorySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    status: getStatus(doc.data().type),
  }));

  // group maintenance records by assetId
  maintenanceHistory = maintenanceHistory.reduce((acc, maintenanceEvent) => {
    if (!acc[maintenanceEvent.assetId]) {
      acc[maintenanceEvent.assetId] = [];
    }
    acc[maintenanceEvent.assetId].push(maintenanceEvent);
    return acc;
  }, {});

  // for each asset, sort the maintenance records by timestamp and keep only the most recent
  maintenanceHistory = Object.keys(maintenanceHistory).map((assetId) => {
    const maintenanceEvents = maintenanceHistory[assetId];
    maintenanceEvents.sort((a, b) => {
      return a.datePerformed - b.datePerformed;
    });
    return maintenanceEvents[maintenanceEvents.length - 1];
  });

  // Add asset name and id to each maintenance record
  maintenanceHistory = maintenanceHistory.map(async (maintenanceEvent) => {
    const assetDoc = await getDoc(doc(db, "assets", maintenanceEvent.assetId));
    const asset = assetDoc.data();
    return {
      ...maintenanceEvent,
      assetName: asset.name,
      assetId: asset.id,
    };
  });

  return await Promise.all(maintenanceHistory);
};

// Add a new maintenance record
export const addMaintenance = async (maintenance) => {
  return await addDoc(maintenanceHistoryEventsCollectionRef, maintenance);
};

// Update an existing maintenance record
export const updateMaintenance = async (id, updatedMaintenance) => {
  const maintenanceDoc = doc(db, "maintenance", id);
  return await updateDoc(maintenanceDoc, updatedMaintenance);
};

// Delete a maintenance record
export const deleteMaintenance = async (id) => {
  const maintenanceDoc = doc(db, "maintenance", id);
  return await deleteDoc(maintenanceDoc);
};
