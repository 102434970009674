import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Layout, message, Divider } from "antd";

import { getOfficer, updateOfficer } from "../services/officerService";
import OfficerForm from "./OfficerForm";
import { UserContext } from "../contexts/UserContext";
import CustomSpinner from "./CustomSpinner";

const { Content } = Layout;

const OfficerEditPage = () => {
  const [officer, setOfficer] = useState(null);
  const { officerId } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchOfficerDetails = async () => {
      // Add organization check here if necessary
      try {
        const officerData = await getOfficer(officerId);
        if (officerData.organizationId === user.organizationId) {
          setOfficer(officerData);
        } else {
          throw new Error("Unauthorized access");
        }
      } catch (error) {
        message.error(`Error: ${error.message}`);
        navigate("/officers");
      }
    };
    fetchOfficerDetails();
  }, [officerId, navigate, user]);

  const handleSave = async (updatedData) => {
    try {
      await updateOfficer(officerId, updatedData);
      message.success("Officer updated successfully");
      navigate("/officers");
    } catch (error) {
      message.error("Error updating officer: " + error.message);
    }
  };

  return (
    <Content
      style={{
        minHeight: "100%",
        background: "white",
      }}
    >
      <Divider style={{ margin: 0 }} />
      <Card
        bordered={false}
        style={{ margin: "0 auto", maxWidth: "992px" }}
        title="Officer Details"
      >
        {officer ? (
          <OfficerForm
            initialData={officer}
            onCancel={() => navigate("/officers")}
            onSave={handleSave}
          />
        ) : (
          <CustomSpinner />
        )}
      </Card>
    </Content>
  );
};

export default OfficerEditPage;
