import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Card,
  Layout,
  Divider,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  ref,
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { UserContext } from "../contexts/UserContext";
import {
  getOrganizationProfile,
  updateOrganizationProfile,
} from "../services/organizationService";

const OrganizationProfile = () => {
  const { user } = useContext(UserContext);
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [imageFileList, setImageFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Content } = Layout;

  useEffect(() => {
    const fetchOrganizationData = async () => {
      if (user && user.organizationId && user.role === "organizationAdmin") {
        try {
          setLoading(true);
          const organizationData = await getOrganizationProfile(
            user.organizationId,
          );
          form.setFieldsValue(organizationData);
        } catch (error) {
          message.error("Failed to load organization data: " + error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOrganizationData();
  }, [user, form]);

  const handleUpload = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `organization_images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        () => {
          // Optional: Handle upload progress
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        },
      );
    });
  };

  //TODO: handledetel image

  const handleImageChange = async (info) => {
    // Process new file for upload
    if (info.fileList.length > 0) {
      setLoading(true);
      const file = info.fileList[0].originFileObj;
      if (!file) {
        setLoading(false);
        return;
      }
      try {
        const newImageUrl = await handleUpload(file);
        setImageFileList([
          { uid: info.fileList[0].uid, url: newImageUrl, status: "done" },
        ]);
        form.setFieldsValue({ image: newImageUrl });
        message.success("Image uploaded successfully");
      } catch (error) {
        message.error(`Failed to upload image: ${error.message}`);
        setImageFileList([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const updatedValues = { ...values };
      if (imageFileList.length > 0 && imageFileList[0].status === "done") {
        updatedValues.image = imageFileList[0].url;
      }

      await updateOrganizationProfile(user.organizationId, updatedValues);
      message.success("Organization profile updated successfully.");
      setIsEditMode(false);
    } catch (error) {
      message.error("Failed to update organization profile: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!user || !user.organizationId) {
    return (
      <p>You must be logged in as an organization admin to view this page.</p>
    );
  }

  console.log("loading", loading);
  return (
    <Content style={{ minHeight: "100vh", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <Card
        bordered={false}
        style={{ margin: "0 auto", maxWidth: "992px" }}
        title="Organization Profile"
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Organization Name"
            name="name"
            rules={[{ required: true }]}
          >
            {isEditMode ? <Input /> : <span>{form.getFieldValue("name")}</span>}
          </Form.Item>
          <Form.Item label="Size" name="size">
            {isEditMode ? <Input /> : <span>{form.getFieldValue("size")}</span>}
          </Form.Item>
          <Form.Item label="Profile Image" name="image">
            {isEditMode ? (
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                fileList={imageFileList}
                listType="picture-card"
                onChange={handleImageChange}
              >
                {imageFileList.length < 1 && <UploadOutlined />}
              </Upload>
            ) : (
              <img
                alt="Profile"
                src={form.getFieldValue("image")}
                style={{ width: "100px" }}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="default" onClick={() => setIsEditMode(!isEditMode)}>
              {isEditMode ? "Cancel" : "Edit"}
            </Button>
            {isEditMode && (
              <Button
                htmlType="submit"
                style={{ marginLeft: "8px" }}
                type="primary"
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};

export default OrganizationProfile;
