import React from "react";
import { Form, Input, Button } from "antd";

const OfficerForm = ({ initialData, onSave, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSave(values);
  };

  return (
    <Form
      form={form}
      initialValues={initialData}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Badge Number"
        name="badgeNumber"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Rank" name="rank" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Unit" name="unit" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button style={{ marginRight: 8 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          Save Officer
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OfficerForm;
