import React from "react";
import { useNavigate } from "react-router-dom";
import { Result, Button, Space } from "antd";

const UnderConstruction = ({ pageName, useButton }) => {
  const navigate = useNavigate();

  const handleGoBackButtonClick = () => {
    navigate("/");
  };

  return (
    <Space
      direction="vertical"
      style={{ marginTop: "64px", width: "100%", justifyContent: "center" }}
    >
      <Result
        extra={
          useButton
            ? [
                <Button
                  key="console"
                  type="primary"
                  onClick={handleGoBackButtonClick}
                >
                  Go Back to Dashboard
                </Button>,
              ]
            : null
        }
        status="warning"
        subTitle={`The ${pageName} page is currently in development and will be available soon!`}
        title="Under Construction"
      />
    </Space>
  );
};

export default UnderConstruction;
