import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";

import { db } from "./firebaseConfig"; // Adjust this import based on your actual config file

const storage = getStorage();

export const addReport = async (reportBlob, reportMetadata, user) => {
  const reportName = `${reportMetadata.title.replace(
    /\s+/g,
    "_",
  )}_${Date.now()}.pdf`;
  const storageRef = ref(storage, `reports/${reportName}`);

  // Upload report to Firebase Storage
  await uploadBytes(storageRef, reportBlob);
  const url = await getDownloadURL(storageRef);

  // Add report metadata to Firestore
  const reportsCollectionRef = collection(db, "reports");
  await addDoc(reportsCollectionRef, {
    ...reportMetadata,
    url,
    organizationId: user.organizationId,
    generatedDate: Timestamp.now(),
  });
};

export const getReports = async (organizationId) => {
  try {
    const reportsQuery = query(
      collection(db, "reports"),
      where("organizationId", "==", organizationId),
    );
    const querySnapshot = await getDocs(reportsQuery);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.log(error);
  }
};

export const deleteReport = async (reportId, reportUrl) => {
  const reportDocRef = doc(db, "reports", reportId);
  await deleteDoc(reportDocRef);

  const reportRef = ref(storage, reportUrl);
  await deleteObject(reportRef);
};
