import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Switch,
  Row,
  Col,
} from "antd";

import CustomSpinner from "./CustomSpinner";
import { getOfficers } from "../services/officerService";

const { Option } = Select;

const CheckOutForm = ({
  form,
  handleCheckoutRequest,
  organizationId,
  barcode,
}) => {
  const isAdmin = true;
  const [isCheckoutForSelf, setIsCheckoutForSelf] = useState(false);
  const [officers, setOfficers] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOfficers = async () => {
      setLoading(true);
      try {
        const officerList = await getOfficers(organizationId);
        setOfficers(officerList);
      } catch (err) {
        // setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOfficers();
  }, [organizationId]);

  return (
    <Form
      form={form}
      initialValues={{
        barcode: barcode, // Set the initial value for the barcode field here
      }}
      layout="vertical"
      onFinish={(formData) => {
        // Include isCheckoutForSelf with the form data
        handleCheckoutRequest({ ...formData, isCheckoutForSelf });
      }}
    >
      {isAdmin && (
        <Form.Item>
          <Row align="middle" gutter={8}>
            <Col>
              <Form.Item
                label="Check out for myself"
                style={{ marginBottom: 0 }}
                valuePropName="checked"
              >
                <Switch
                  checked={isCheckoutForSelf}
                  onChange={setIsCheckoutForSelf}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      )}
      {!isCheckoutForSelf && (
        <Form.Item
          label="Officer Name"
          name="assignedOfficerId"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              officers
                .find((officer) => officer.id === option.value)
                .firstName.toLowerCase()
                .includes(input.toLowerCase()) ||
              officers
                .find((officer) => officer.id === option.value)
                .lastName.toLowerCase()
                .includes(input.toLowerCase())
            }
            loading={loading}
            notFoundContent={loading ? <CustomSpinner /> : "No officers found"}
            placeholder="Select an Officer"
          >
            {officers.map((officer) => (
              <Option key={officer.id} value={officer.id}>
                {officer.firstName} {officer.lastName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            label="Checkout Date"
            name="checkoutDate"
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Expected Return Date"
            name="returnDate"
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Barcode" name="barcode" rules={[{ required: true }]}>
        <Input placeholder="Scan or enter barcode" />
      </Form.Item>
      <Form.Item
        label="Condition"
        name="condition"
        rules={[{ required: true }]}
      >
        <Select placeholder="Asset condition at time of checkout">
          <Option value="New">New</Option>
          <Option value="Good">Good</Option>
          <Option value="Fair">Fair</Option>
          <Option value="Poor">Poor</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" justify="center" type="primary">
          Request Checkout
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CheckOutForm;
