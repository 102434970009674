import React from "react";
import { Divider, Layout } from "antd";

// import Report from "./Report";
// import Report2 from "./Report2";
import ReportsFeature from "./ReportsFeature";
const { Content } = Layout;

const ReportsPage = () => {
  return (
    <Content style={{ minHeight: "100%", background: "white" }}>
      <Divider style={{ margin: 0 }} />
      <ReportsFeature />
      {/* <Report />
      <Report2 officerId="b9bafcf3-b358-4a24-968e-551efb85a444" /> */}
    </Content>
  );
};

export default ReportsPage;
